import {
    Button,
    Grid,
    FormControl,
    TextField,
    Typography
} from '@material-ui/core';
import API from 'API';
import React from 'react';
import { connect } from 'react-redux';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import _ from 'lodash';
import JobSheetForm from './JobSheetForm';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import JobSheetCompleteForm from './JobSheetCompleteForm';
import JobSheetScheduledForm from './JobSheetScheduledForm';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import CheckBox from 'Components/Common/Inputs/CheckBox';
import IconHelper from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';
import StickyNotes        from 'Components/Common/StickyNotes/StickyNotes';


const initialState = (props={}) => ({
    searchResults: [],
    isLoading: true,
    formData: {
        search: '',
        siteId: props?.siteId ?? null,
        customerId: props?.customerId ?? null,
        status: ['Pending', 'Scheduled'],
        monthlyServicePlan: 0
    },
})

class JobSheetSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentWillUnmount = () => {
        if(this.timeout) 
            clearTimeout(this.timeout)
    }

    loadComponentData = () => {
        this.getSearchData();
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 500);
    }

    getSearchData = () => {
        // this.setState({
        //     isLoading: true
        // }, () => {
            API.get('/engineers/jobSheets', {params: this.state.formData})
            .then(result => {
                this.setState({
                    isLoading: false,
                    searchResults: result.data
                });
            });
        // });
    }

    download = rowData => {
        API.get('/engineers/jobSheets/' + rowData.id)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleShowStickyNote = jsId => { this.props.deployDialog(<StickyNotes jobSheetId={jsId} edit dialog/>, 'Sticky Notes', null,'xs') }

    render() {
        const { isLoading, formData } = this.state;

        if (isLoading) {
            return <LoadingCircle />
        }

        return (
            <>
                <Grid container spacing={2} style={{alignItems: 'center'}}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Jobs Sheet Search
                        </Typography>
                    </Grid> 
                    <Grid item xs={12} lg={4}>
                        <FormControl margin="none" fullWidth>
                            <TextField label="Keywords" fullWidth value={formData?.search} onChange={(e) => {
                                this.setState({formData: {...formData, search: e.target.value}},  this.setSearch());
                            }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormControl margin="none" fullWidth>
                            <AutoCompleteMultiSelect 
                                label="Status"
                                options={[
                                    {value:'Pending', label: 'Pending'}, 
                                    {value:'Scheduled', label: 'Scheduled'}, 
                                    {value:'Complete', label: 'Complete'}, 
                                    {value:'Cancelled', label: 'Cancelled'}, 
                                ]}
                                value={formData?.status}
                                onChange={selectedOptions => {
                                    let values = selectedOptions ? selectedOptions.map(a => a.value) : ['Pending', 'Scheduled'];
                                    this.setState({formData: {...formData, status: values}}, this.setSearch)}
                                } 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item  >
                        <CheckBox 
                            label="Show Monthly Service Plan"
                            checked={formData.monthlyServicePlan}
                            onChange={(e)=>this.setState({ formData: { ...formData, monthlyServicePlan: e.target.checked ? 1 : 0 } }, this.setSearch)}
                            color="primary"
                        />
                    </Grid>
                    <Grid item >
                        <Button
                            variant="outlined"
                            onClick={()=>{
                                this.setState(initialState(this.props), () => {
                                    this.getSearchData();
                                });
                            }}
                        >
                            Reset Search
                        </Button>
                    </Grid>
                    <Grid item style={{marginLeft: 'auto'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={()=>
                                this.props.deployDialog(
                                    <JobSheetForm 
                                        onClose={()=>{this.props.closeDialog(); this.loadComponentData();}}
                                        defaultSiteId={formData.siteId}
                                        defaultCustomerId={formData.customerId}
                                    />,
                                    'Create New Job Sheet',
                                    'system',
                                    'fullScreen'
                                )
                            }
                        >
                            Create New Job Sheet 
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <CiDataTable 
                                config={{
                                    key: 'id'
                                }}
                                rows={this.state.searchResults}
                                columns={[
                                    {
                                        heading: 'Reference Number',
                                        field: 'ref',
                                        dataRef: 'ref',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Customer',
                                        field: rowData => <div style={{
                                            cursor: 'pointer',
                                        }} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.customerId}`); }}>
                                            {rowData.customer}
                                        </div>,
                                        dataRef: 'customer',
                                        truncate: true,
                                    },
                                    {
                                        heading: 'Site',
                                        field: rowData => <div style={{
                                            cursor: 'pointer',
                                        }} onClick={()=>{ this.props.history.push(`/customers/addresses/view/${rowData.siteId}`); }}>
                                            {rowData.site}
                                        </div>,
                                        dataRef: 'site',
                                        truncate: true,
                                    },
                                    {
                                        heading: 'Service Plan',
                                        field: 'msp',
                                        dataRef: 'msp',
                                        fieldFormat: 'boolean',
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Status',
                                        field: 'status',
                                        dataRef: 'status',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Pending',
                                        field: 'date',
                                        dataRef: 'date',
                                        fieldFormat: 'date',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Scheduled',
                                        field: 'completedDate',
                                        dataRef: 'completedDate',
                                        fieldFormat: 'date',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Completed',
                                        field: 'completed',
                                        dataRef: 'completed',
                                        fieldFormat: 'date',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Cancelled',
                                        field: 'cancelled',
                                        dataRef: 'cancelled',
                                        fieldFormat: 'date',
                                        sizeToContent: true
                                    },
                                    {
                                        actions: i => ([
                                            {   
                                                name: 'Sticky Notes', 
                                                icon: IconHelper.stickyNote, 
                                                onClick: () => this.handleShowStickyNote(i.gId), color: (_.filter(i?.sticky_notes, i => !i.sn_deleted && !i.sn_completed).length > 0 && colors.yellow), 
                                            },
                                            {
                                                name: 'Download',
                                                icon: 'download',
                                                onClick: () => this.download(i)
                                            },
                                            {   
                                                name: i.status === 'Pending' ? 'Set as Scheduled' : (i.status === 'Scheduled' ? 'Set as Completed' : 'See Order'),
                                                icon: i.status === 'Pending' ? 'calendar' : (i.status === 'Scheduled' ? 'check' : 'search'),
                                                onClick: () => {
                                                    if(i.status === 'Pending') {
                                                        this.props.deployDialog(
                                                            <JobSheetScheduledForm 
                                                                jobSheet={i}
                                                                onClose={()=>{this.props.closeDialog(); this.loadComponentData();}}
                                                            />,
                                                            'Schedule Job Sheet',
                                                            'system',
                                                            'sm'
                                                        )
                                                    } else if(i.status === 'Scheduled') {
                                                        this.props.deployDialog(
                                                            <JobSheetCompleteForm 
                                                                jobSheet={i}
                                                                onClose={()=>{this.props.closeDialog(); this.loadComponentData();}}
                                                            />,
                                                            'Complete Job Sheet',
                                                            'system',
                                                            'sm'
                                                        )
                                                    } else {
                                                        window.open('/sales/order/view/' + i.orderId, '_blank');
                                                    }
                                                },
                                                disabled: i.status === 'Cancelled' || (i.status === 'Complete' && !i.orderId)
                                            },
                                            {
                                                name: 'Set as Pending',
                                                icon: 'clock-rotate-left',
                                                onClick: () => this.props.deployConfirmation('Are you sure you want to set this job sheet as Pending?', 'Set as Pending', () =>
                                                    API.post('/engineers/jobSheets/' + i.id + '/updateStatus', {
                                                        status: 'Pending'
                                                    })
                                                    .then(() => this.loadComponentData())
                                                ),
                                                disabled: i.status !== 'Scheduled'
                                            },
                                            {
                                                name: 'Cancel',
                                                icon: 'trash',
                                                onClick: () => this.props.deployConfirmation('Are you sure you want to set this job sheet as Cancelled?', 'Set as Cancelled', () =>
                                                    API.post('/engineers/jobSheets/' + i.id + '/updateStatus', {
                                                        status: 'Cancelled'
                                                    })
                                                    .then(() => this.loadComponentData())
                                                ),
                                                disabled: i.status === 'Complete' || i.status === 'Cancelled'
                                            },
                                            {
                                                name: 'Edit',
                                                icon: 'pencil',
                                                onClick: () =>
                                                    this.props.deployDialog(
                                                        <JobSheetForm 
                                                            jobSheet={i}
                                                            onClose={()=>{this.props.closeDialog(); this.loadComponentData();}}
                                                        />,
                                                        'Update Job Sheet',
                                                        'system',
                                                        'fullScreen'
                                                    ),
                                                disabled: i.status === 'Complete' || i.status === 'Cancelled'
                                            },
                                            
                                        ])
                                    }
                                ]}
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, variant, size='md')  => dispatch(deployDialog(content, title, variant, size)),
    closeDialog: () => dispatch(closeDialog()),
    deployConfirmation: (content, title, onConfirm) => dispatch(deployConfirmation(content, title, onConfirm))
});

export default connect(mapStateToProps, mapDispatchToProps)(JobSheetSearch);