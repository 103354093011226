import React from 'react';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';
import API from 'API';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import _ from 'lodash';
import AddressLookup from 'Components/Common/AddressLookup/AddressLookup';

const initialState = (props={}) => ({
    formData: {
        id: props?.jse_id || 0,
        name: props?.jse_name || '',
        company: props?.jse_company || '',
        address: props?.jse_address || '',
        notes: props?.jse_notes || '',
        selectedAddress: null,
        showAddressLookup: false,   
    },
});

class JobSheetEngineerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    save = () => {
        API.post(
            '/engineers/jobSheets/engineers',
            this.state.formData
        ).then(({data})=>{
            if (this.props.onSubmit) this.props.onSubmit(data);
            this.props.onClose();

        });
    }

    handleSelectedAddress = (address) => {
        if (!address) return;
        this.setState({
            formData: {
                ...this.state.formData,
                address: _.filter([
                    address.value?.line1,
                    address.value?.line2,
                    address.value?.line3,
                    address.value?.town,
                    address.value?.county,
                    address.value?.country,
                    address.value?.postcode,
                ], i=>i).join('\n'), 
                selectedAddress: address,
                showAddressLookup: false,
            }
        });
    }

    render() {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField 
                            label="Name"
                            value={this.state.formData.name}
                            onChange={(e) => this.setState({ formData: { ...this.state.formData, name: e.target.value } })}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutoCompleteSelect 
                            fullWidth
                            label="Company"
                            options={this.props.companies ? this.props.companies : []}
                            value={this.state.formData.company}
                            onChange={(value) => this.setState({ formData: { ...this.state.formData, company: value.value } })}
                            creatable
                        />
                    </Grid>
                    {!!this.state.formData.showAddressLookup ?
                        <Grid item xs={12}>
                            <AddressLookup 
                                handleSelectedAddress={this.handleSelectedAddress}
                                value={this.state.formData.selectedAddress}
                            />
                        </Grid> :
                        <>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Textarea 
                                            label="Address"
                                            value={this.state.formData.address}
                                            fullWidth
                                            rows={4}
                                             onChange={(e) => this.setState({ formData: { ...this.state.formData, address: e.target.value } })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className='buttonRow'>
                                        <Button 
                                            variant="outlined"
                                            onClick={()=>this.setState({ formData: { ...this.state.formData, showAddressLookup: true, address: null } })}
                                        >
                                            Search Address
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                        <Textarea 
                            label="Notes"
                            value={this.state.formData.notes}
                            onChange={(e) => this.setState({ formData: { ...this.state.formData, notes: e.target.value } })}
                            fullWidth
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="outlined"
                            onClick={this.props.onClose}
                        >
                            Close
                        </Button>
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={()=>this.props.deployConfirmation(
                                'Are you sure you want to save this Engineer?',
                                'Save Engineer',
                                this.save
                            )}
                            disabled={!this.state.formData.name || !this.state.formData.company || !this.state.formData.address}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
});

export default connect(null, mapDispatchToProps)(JobSheetEngineerForm);