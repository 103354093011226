import React from 'react';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { connect } from 'react-redux';

import { Box, Button, Grid } from '@material-ui/core';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import moment from 'moment';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

class JobSheetScheduledForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                dateWorkToBeCompleted: props.jobSheet?.completedDate ? moment(props.jobSheet.completedDate) : moment(),
                engineerCompany:  props.jobSheet?.data?.engineerCompany,
                engineerName:  props.jobSheet?.data?.engineerName,
                engineerId:  props.jobSheet?.data?.engineerId,
                engineerAddress:  props.jobSheet?.data?.engineerAddress,
            },
            lists: {
                engineers: [],
            },
            isLoading: true,
        };
    }

    componentDidMount() {
        this.loadComponentData();
    }

    loadComponentData() {
        API.get('/engineers/jobSheets/engineers').then(engineersRes => {
            this.setState({
                lists: {
                    ...this.state.lists,
                    engineers: engineersRes.data.map(i => ({
                        value: i.jse_id,
                        label: i.jse_name,
                        company: i.jse_company,
                        data: i,
                    })),
                },
                isLoading: false,
            });
        });
    }


    render() {
        const { formData, lists, isLoading } = this.state;

        if (isLoading) { return (<LoadingCircle/>) }

        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DatePicker 
                            type="date"
                            id="date"
                            name="date"
                            label="Date Work To Be Completed"
                            value={formData.dateWorkToBeCompleted}
                            onChange={e=>this.setState({ formData: { ...formData, dateWorkToBeCompleted: moment(e) }})}
                            autoOk={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutoCompleteSelect 
                            label="Engineer"
                            value={this.state.formData.engineerId}
                            options={formData.engineerCompany ? this.state.lists.engineers.filter(i => i.company === formData.engineerCompany) : this.state.lists.engineers}
                            fullWidth
                            onChange={(j) => this.setState({ 
                                formData: { 
                                    ...formData, 
                                    engineerId: j.value, 
                                    engineerName: j.label,
                                    engineerCompany: j.company,
                                    engineerAddress: j.data.jse_address,
                                } 
                            })}
                            noClear
                        />
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="outlined"
                            onClick={this.props.onClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!formData.dateWorkToBeCompleted || !formData.engineerId}
                            onClick={()=>{
                                this.props.deployConfirmation(
                                    'Are you sure you want to set this job sheet as scheduled?',
                                    'Set Job Sheet as Scheduled',
                                    () => {
                                        API.post('/engineers/jobSheets/' + this.props.jobSheet.id + '/updateStatus', 
                                            {
                                                status: 'Scheduled',
                                                dateWorkToBeCompleted: formData.dateWorkToBeCompleted,
                                                engineerCompany: formData.engineerCompany,
                                                engineerName: formData.engineerName,
                                                engineerId: formData.engineerId,
                                                engineerAddress: formData.engineerAddress,
                                            })
                                        .then(() => {
                                            this.props.onClose();
                                            // this.props.onComplete();
                                        });
                                    }
                                )
                            }}
                        >
                            Set Scheduled
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (content, title, onConfirm) => dispatch(deployConfirmation(content, title, onConfirm))
});

export default connect(null, mapDispatchToProps)(JobSheetScheduledForm);