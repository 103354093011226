import {
    Button,
    Grid,
    Typography
} from '@material-ui/core';
import API from 'API';
import React from 'react';
import { connect } from 'react-redux';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import JobSheetEngineersForm from './JobSheetEngineersForm';
import _, { truncate } from 'lodash';

const initialState = () => ({
    searchResults: [],
    isLoading: true,
    formData: {
        search: '',
        type: null,
    },
})

class JobSheetEngineersSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentWillUnmount = () => {
        if(this.timeout) 
            clearTimeout(this.timeout)
    }



    loadComponentData = () => {
        this.getSearchData();
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/engineers/jobSheets/engineers')
            .then(result => {
                this.setState({
                    isLoading: false,
                    searchResults: result.data
                });
            });
        });
    }

    render() {
        const { isLoading } = this.state;

        if (isLoading) {
            return <LoadingCircle />
        }

        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Jobs Sheet Engineer Search
                        </Typography>
                    </Grid> 
                    <Grid item style={{marginLeft: 'auto'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={()=>
                                this.props.deployDialog(
                                    <JobSheetEngineersForm 
                                        onClose={()=>{this.props.closeDialog(); this.loadComponentData();}}
                                        companies={_.map(_.uniqBy(this.state.searchResults, 'jse_company'), i => ({
                                            value: i.jse_company,
                                            label: i.jse_company,
                                            address: i.jse_address
                                        }))}
                                    />,
                                    'Create New Engineer',
                                    null,
                                    'md'
                                )
                            }
                        >
                            Create New Engineer 
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <CiDataTable 
                                config={{
                                    key: 'jse_id'
                                }}
                                rows={this.state.searchResults}
                                columns={[
                                    {
                                        heading: 'Company',
                                        field: 'jse_company',
                                        dataRef: 'jse_company',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Name',
                                        field: 'jse_name',
                                        dataRef: 'jse_name',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Address',
                                        field: i => i.jse_address.replace(/\n/g, ', '),
                                        dataRef: i => i.jse_address.replace(/\n/g, ', '),
                                        truncate: true
                                    },
                                    {
                                        actions: i => ([
                                            {
                                                name: 'Edit',
                                                icon: 'pencil',
                                                onClick: () => this.props.deployDialog(
                                                    <JobSheetEngineersForm 
                                                        {...i}
                                                        companies={_.map(_.uniqBy(this.state.searchResults, 'jse_company'), i => ({
                                                            value: i.jse_company,
                                                            label: i.jse_company,
                                                            address: i.jse_address
                                                        }))}
                                                        onClose={()=>{this.props.closeDialog(); this.loadComponentData();}}
                                                    />,
                                                    'Edit Engineer',
                                                    null,
                                                    'md'
                                                )
                                            }
                                        ])
                                    }
                                ]}
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, variant, size='md')  => dispatch(deployDialog(content, title, variant, size)),
    closeDialog: () => dispatch(closeDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobSheetEngineersSearch);