import React from 'react';
import { Box, Grid, Button, Typography, TextField, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import API from 'API';
import _ from 'lodash';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea from 'Components/Common/Inputs/Textarea';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import moment from 'moment';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import JobSheetEngineersForm from 'Components/Engineers/JobSheets/Engineers/JobSheetEngineersForm';
import CheckBox from 'Components/Common/Inputs/CheckBox';

const initialState = (props={}) => ({
    isLoading: true,
    lists: {
        companies: [],
        engineers: [],
        customers: [],
        contacts: [],
        equipment: [],
        dowTemplates: [],
        noteTemplates: [],
    },
    dialog: {
        showAddEngineer: false,
    },
    formData: {
        engineerCompany: null,
        engineerId: null,
        customerId: props?.defaultCustomerId ? parseFloat(props.defaultCustomerId) : null,   
        siteId: props?.defaultSiteId ? parseFloat(props.defaultSiteId) : null,
        contactId: null,
        monthlyServicePlan: 0,
        
        engineerAddress: null,
        date: moment(),
        staffName: props.loggedInStaff.firstName + ' ' + props.loggedInStaff.lastName,
        staffId: props.loggedInStaff.staff_id,
        siteName: null,
        siteAddress: null,
        customerName: null,
        customerRef: null,
        contactName: null,
        siteName: null,
        dateWorkToBeCompleted: null,
        equipmentId: null,
        engineerToProvide: 0,
        machineType: '',
        serialNumber: '',
        
        jobSheetType: null,
        CTUExchange: 0,
        customerType: null,
        warrantyStatus: null,
        dowTemplate: null,
        dow: '',
        
        notesTemplate: null,
        notes: '',
        ...(props?.jobSheet?.data ? props.jobSheet.data : {}),
        ref: props?.jobSheet?.ref ? props?.jobSheet?.ref : 'TBD',
    }

})  

class JobSheetForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount = () => {
        if (this.props?.jobSheet){
            this.loadJobSheet();
        } else if (this.props?.defaultCustomerId) {
            this.loadDefaultedData();
        } else {
            this.loadComponentData();
        }
    }

    loadDefaultedData = () => {
        Promise.all([
            API.get('/engineers/jobSheets/engineers'),
            API.get('/engineers/jobSheets/templates'),
            API.get('/customers'),
            API.get('/equipment', { 
                params: {
                    isLatest: true,
                    customerId: this.state.formData.customerId,
                    addressId: this.state.formData.siteId,
                } 
            })
        ]).then(([engineersRes, templatesRes, customersRes, equipmentRes]) => {

            let selectedCustomer = _.find(customersRes.data, i => parseFloat(i.cust_id) === parseFloat(this.props?.defaultCustomerId));
            let selectedAddress = null;
            if (this.props?.defaultSiteId) {
                 selectedAddress = _.find(selectedCustomer?.addresses, i => parseFloat(i.address_id) === parseFloat(this.props?.defaultSiteId));
            }

            this.setState({
                isLoading: false,
                formData: {
                    ...this.state.formData,
                    customerName: selectedCustomer.cust_name,
                    customerRef: selectedCustomer.cust_ref,
                    siteName: selectedAddress ? selectedAddress.address_name : null,
                    siteTelephone: selectedAddress ? selectedAddress.address_phone : null,
                    siteAddress: selectedAddress ? _.filter([
                                        selectedAddress.address_line_one,
                                        selectedAddress.address_line_two,
                                        selectedAddress.address_line_three,
                                        selectedAddress.address_town,
                                        selectedAddress?.county?.county_name,
                                        selectedAddress.address_postcode,
                                    ], i=>i).join('\n') : null,
                },
                lists: {
                    ...this.state.lists,
                    equipment: equipmentRes.data.map(i => ({
                        value: i.id,
                        label: `${i.partDesc} (${i.sn})`,
                        data: i,
                    })),
                    companies: _.map(_.uniqBy(engineersRes.data, 'jse_company'), i => ({
                        value: i.jse_company,
                        label: i.jse_company,
                        data: i,
                    })),
                    engineers: engineersRes.data.map(i => ({
                        value: i.jse_id,
                        label: i.jse_name,
                        company: i.jse_company,
                        data: i,
                    })),
                    customers: customersRes.data.map(i => ({
                        value: i.cust_id,
                        label: i.cust_name,
                        data: i,
                    })),
                    dowTemplates: _.filter(templatesRes.data, i => i.jst_type === 'work').map(i => ({
                        value: i.jst_id,
                        label: i.jst_name,
                        data: i,
                    })),
                    noteTemplates: _.filter(templatesRes.data, i => i.jst_type === 'notes').map(i => ({
                        value: i.jst_id,
                        label: i.jst_name,
                        data: i,
                    })),
                    sites: _.map(_.filter(selectedCustomer?.addresses, i => i.address_type === 'Site'), i => ({
                        value: i.address_id,
                        label: `${i.address_name} (${i.address_postcode})`,
                        data: i
                    })),
                    contacts: _.map(selectedAddress?.active_contacts, i => ({
                        value: i.contact_id,
                        label: i.contact_name,
                        data: i
                    })),
                }
            })
        })
    }

    loadJobSheet = () => {
        Promise.all([
            API.get('/engineers/jobSheets/engineers'),
            API.get('/engineers/jobSheets/templates'),
            API.get('/customers'),
            API.get('/equipment', { 
                params: {
                    isLatest: true,
                    customerId: this.state.formData.customerId,
                    addressId: this.state.formData.siteId,
                } 
            })
        ]).then(([engineersRes, templatesRes, customersRes, equipmentRes]) => {

            let selectedCustomer = _.find(customersRes.data, i => parseFloat(i.cust_id) === parseFloat(this.state.formData.customerId));
            let selectedAddress = _.find(selectedCustomer?.addresses, i => parseFloat(i.address_id) === parseFloat(this.state.formData.siteId));

            this.setState({
                isLoading: false,
                lists: {
                    ...this.state.lists,
                    equipment: equipmentRes.data.map(i => ({
                        value: i.id,
                        label: `${i.partDesc} (${i.sn})`,
                        data: i,
                    })),
                    companies: _.map(_.uniqBy(engineersRes.data, 'jse_company'), i => ({
                        value: i.jse_company,
                        label: i.jse_company,
                        data: i,
                    })),
                    engineers: engineersRes.data.map(i => ({
                        value: i.jse_id,
                        label: i.jse_name,
                        company: i.jse_company,
                        data: i,
                    })),
                    customers: customersRes.data.map(i => ({
                        value: i.cust_id,
                        label: i.cust_name,
                        data: i,
                    })),
                    dowTemplates: _.filter(templatesRes.data, i => i.jst_type === 'work').map(i => ({
                        value: i.jst_id,
                        label: i.jst_name,
                        data: i,
                    })),
                    noteTemplates: _.filter(templatesRes.data, i => i.jst_type === 'notes').map(i => ({
                        value: i.jst_id,
                        label: i.jst_name,
                        data: i,
                    })),
                    sites: _.map(_.filter(selectedCustomer?.addresses, i => i.address_type === 'Site'), i => ({
                        value: i.address_id,
                        label: `${i.address_name} (${i.address_postcode})`,
                        data: i
                    })),
                    contacts: selectedAddress ? _.map(selectedAddress?.active_contacts, i => ({
                        value: i.contact_id,
                        label: i.contact_name,
                        data: i
                    })) : [],
                }
            })
        })
    }

    loadComponentData = () => {
        Promise.all([
            API.get('/engineers/jobSheets/engineers'),
            API.get('/engineers/jobSheets/templates'),
            API.get('/customers')
        ]).then(([engineersRes, templatesRes, customersRes]) => {
            this.setState({
                isLoading: false,
                lists: {
                    ...this.state.lists,
                    companies: _.map(_.uniqBy(engineersRes.data, 'jse_company'), i => ({
                        value: i.jse_company,
                        label: i.jse_company,
                        data: i,
                    })),
                    engineers: engineersRes.data.map(i => ({
                        value: i.jse_id,
                        label: i.jse_name,
                        company: i.jse_company,
                        data: i,
                    })),
                    customers: customersRes.data.map(i => ({
                        value: i.cust_id,
                        label: i.cust_name,
                        data: i,
                    })),
                    dowTemplates: _.filter(templatesRes.data, i => i.jst_type === 'work').map(i => ({
                        value: i.jst_id,
                        label: i.jst_name,
                        data: i,
                    })),
                    noteTemplates: _.filter(templatesRes.data, i => i.jst_type === 'notes').map(i => ({
                        value: i.jst_id,
                        label: i.jst_name,
                        data: i,
                    })),
                }
            })
        })
    }

    getEquipmentList = () => {
        this.setState({
            lists: {
                ...this.state.lists,
                equipment: []
            },
            formData: {
                ...this.state.formData,
                equipmentId: null,
                machineType: '',
                serialNumber: '',
            }
        }, ()=>{
            API.get('/equipment', { 
                params: {
                    isLatest: true,
                    customerId: this.state.formData.customerId,
                    addressId: this.state.formData.siteId,
                } 
            }).then(({data}) => {
                this.setState({
                    lists: {
                        ...this.state.lists,
                        equipment: data.map(i => ({
                            value: i.id,
                            label: `${i.partDesc} (${i.sn})`,
                            data: i,
                        }))
                    }
                })
            })
        })
    }

    submit = () => {
        API.post(
            this.props?.jobSheet ? '/engineers/jobSheets/' + this.props.jobSheet.id : '/engineers/jobSheets', {data: JSON.stringify(this.state.formData)}).then(() => {
            this.props.onClose();
        })
    }

    addEngineer = (newEngineer) => {
        API.get('/engineers/jobSheets/engineers').then(({data}) => {
            let engineer = _.find(data, i => parseInt(i.jse_id) === parseInt(newEngineer.jse_id));
            this.setState({
                formData: {
                    ...this.state.formData,
                    engineerId: engineer.jse_id, 
                    engineerName: engineer.jse_name,
                    engineerCompany: engineer.jse_company,
                    engineerAddress: engineer.jse_address,
                },
                lists: {
                    ...this.state.lists,
                    engineers: data.map(i => ({
                        value: i.jse_id,
                        label: i.jse_name,
                        company: i.jse_company,
                        data: i,
                    })),
                }
            })
        })
    }

    render() {

        const { isLoading, formData, lists, dialog } = this.state;

        if(isLoading) {
            return <LoadingCircle />
        }

        return (
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <PaddedPaper>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <Typography variant="body1">Engineer</Typography>
                                </Grid>
                                <Grid item style={{marginLeft: 'auto'}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={()=>this.setState({ dialog: { ...dialog, showAddEngineer: true } })}
                                    >Add Engineer</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect 
                                        label="Company"
                                        value={formData.engineerCompany}
                                        options={lists.companies}
                                        fullWidth
                                        onChange={(j) => this.setState({ 
                                            formData: { 
                                                ...formData, 
                                                engineerCompany: j.value,
                                                engineerAddress: j.data.jse_address,
                                            }})}
                                        noClear
                                    />
                                </Grid>
                                {!!formData.engineerAddress &&    
                                    <Grid item xs={12}>
                                        <Textarea 
                                            label="Address"
                                            value={formData.engineerAddress}
                                            fullWidth
                                            rows={3}
                                            disabled
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <AutoCompleteSelect 
                                        label="Engineer"
                                        value={this.state.formData.engineerId}
                                        options={formData.engineerCompany ? this.state.lists.engineers.filter(i => i.company === formData.engineerCompany) : this.state.lists.engineers}
                                        fullWidth
                                        onChange={(j) => this.setState({ 
                                            formData: { 
                                                ...formData, 
                                                engineerId: j.value, 
                                                engineerName: j.label,
                                                engineerCompany: j.company,
                                                engineerAddress: j.data.jse_address,
                                            } 
                                        })}
                                        noClear
                                    />
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={4}>
                        <PaddedPaper>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CheckBox 
                                        label="This is monthly service plan"
                                        checked={formData.monthlyServicePlan}
                                        onChange={(e)=>this.setState({ formData: { ...formData, monthlyServicePlan: e.target.checked ? 1 : 0 } })}
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Date"
                                        value={moment(formData.date).format('DD/MM/YYYY')}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Reference"
                                        value={formData.ref}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Staff"
                                        value={formData.staffName}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={8}>
                        <PaddedPaper>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">Customer</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect  
                                        label="Customer"
                                        value={formData.customerId}
                                        options={lists.customers}
                                        fullWidth
                                        onChange={({value, data}) => this.setState({ 
                                            formData: { 
                                                ...formData, 
                                                customerId: value,
                                                customerName: data.cust_name,
                                                customerRef: data.cust_ref,
                                                siteAddress: null,
                                                siteId: null,
                                            }, 
                                            lists: { 
                                                ...lists, 
                                                sites: _.map(_.filter(data?.addresses, i => i.address_type === 'Site'), i => ({
                                                    value: i.address_id,
                                                    label: `${i.address_name} (${i.address_postcode})`,
                                                    data: i
                                                })) 
                                            } 
                                        })}
                                        noClear
                                    />
                                </Grid>
                                {!!formData.customerId && 
                                    <>
                                        <Grid item xs={12}>
                                            <AutoCompleteSelect  
                                                label="Site"
                                                value={formData.siteId}
                                                options={lists.sites}
                                                fullWidth
                                                onChange={({value, data}) => this.setState({ 
                                                    formData: { 
                                                        ...formData, 
                                                        siteId: value,
                                                        siteName: data.address_name,
                                                        siteTelephone: data.address_phone,
                                                        siteAddress: _.filter([
                                                                            data.address_line_one,
                                                                            data.address_line_two,
                                                                            data.address_line_three,
                                                                            data.address_town,
                                                                            data?.county?.county_name,
                                                                            data.address_postcode,
                                                                        ], i=>i).join('\n'),
                                                    }, 
                                                    lists: { 
                                                        ...lists, 
                                                        contacts: _.map(data?.active_contacts, i => ({
                                                            value: i.contact_id,
                                                            label: i.contact_name,
                                                            data: i
                                                        })
                                                    )}}, this.getEquipmentList)}
                                                noClear
                                            />
                                        </Grid>
                                        {!!formData.siteAddress &&    
                                            <Grid item xs={12}>
                                                <Textarea 
                                                    label="Address"
                                                    value={formData.siteAddress}
                                                    fullWidth
                                                    rows={3}
                                                    disabled
                                                />
                                            </Grid>
                                        }
                                        {!!formData.siteId &&
                                            <Grid item xs={12}>
                                                <AutoCompleteSelect  
                                                    label="Contact"
                                                    value={formData.contactId}
                                                    options={lists.contacts}
                                                    fullWidth
                                                    onChange={({value, label, data}) => this.setState({ 
                                                        formData: { 
                                                            ...formData, 
                                                            contactId: value,
                                                            contactName: label,
                                                            contactTelephone: data.contact_telephone,
                                                            contactMobile: data.contact_mobile,
                                                        } })}
                                                    noClear
                                                />
                                            </Grid>
                                        }
                                    </>
                                }
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={4}>
                        <PaddedPaper>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <DatePicker 
                                        type="date"
                                        id="date"
                                        name="date"
                                        label="Date Work To Be Completed"
                                        value={formData.dateWorkToBeCompleted}
                                        onChange={e=>this.setState({ formData: { ...formData, dateWorkToBeCompleted: moment(e) }})}
                                        autoOk={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Contact Number Phone"
                                        value={formData.contactTelephone ? formData.contactTelephone : (formData.siteTelephone ? formData.siteTelephone : 'TBD')}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Contact Number Mobile"
                                        value={formData.contactMobile ? formData.contactMobile : 'TBD'}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <PaddedPaper>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}> 
                                            <AutoCompleteSelect 
                                                label="Equipment"
                                                fullWidth
                                                options={lists.equipment}
                                                value={formData.equipmentId}
                                                onChange={(j) => this.setState({ 
                                                    formData: { 
                                                        ...formData, 
                                                        equipmentId: j ? j.value : null,
                                                        machineType: j ? j.data.partType : '',
                                                        serialNumber: j ? j.data.sn : '',
                                                    } 
                                                })}
                                            />
                                        </Grid>
                                        <CheckBox 
                                            label="Engineer To Provide Details?"
                                            checked={formData.engineerToProvide}
                                            onChange={(e)=>this.setState({ formData: { ...formData, 
                                                engineerToProvide: e.target.checked ? 1 : 0,
                                                machineType: e.target.checked ? 'Engineer To Provide' : formData.machineType,
                                                serialNumber: e.target.checked ? 'Engineer To Provide' : formData.serialNumber,
                                            } })}
                                            color="primary"
                                        />
                                        <Grid item xs={12}> 
                                            <TextField 
                                                label="Machine Type"
                                                fullWidth
                                                value={formData.machineType}
                                                onChange={(e) => this.setState({ formData: { ...formData, machineType: e.target.value } })}
                                            />
                                        </Grid>
                                        <Grid item xs={12}> 
                                            <TextField 
                                                label="Serial Number"
                                                fullWidth
                                                value={formData.serialNumber}
                                                onChange={(e) => this.setState({ formData: { ...formData, serialNumber: e.target.value } })}
                                            />
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={8}>
                                <PaddedPaper>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">Description Of Work To Be Undertaken</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <AutoCompleteSelect 
                                                label="Job Sheet Type"
                                                value={formData.jobSheetType}
                                                options={_.map(
                                                    [
                                                        'Machine Maintenance Service',
                                                        'Machine Breakdown Repair',
                                                        'Machine Maintenance Service / Breakdown Repair',
                                                        'Machine Installation',
                                                        'Machine Assessment',
                                                    ], i => ({ value: i, label: i })
                                                )}
                                                onChange={(j) => this.setState({ formData: { ...formData, jobSheetType: j?.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <AutoCompleteSelect 
                                                label="CTU Exchange"
                                                value={formData.CTUExchange}
                                                options={[
                                                    {value: 0, label: 'No'},
                                                    {value: 1, label: 'Yes'},
                                                ]}
                                                onChange={(j) => this.setState({ formData: { ...formData, CTUExchange: j?.value ? 1 : 0 } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <AutoCompleteSelect 
                                                label="Customer Type"
                                                value={formData.customerType}
                                                options={_.map(
                                                    [
                                                        'Service Plan Customer',
                                                        'PAYG Customer',
                                                        'Star Pubs and Bars',
                                                    ], i => ({ value: i, label: i })
                                                )}
                                                onChange={(j) => this.setState({ formData: { ...formData, customerType: j?.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <AutoCompleteSelect 
                                                label="Warranty Status"
                                                value={formData.warrantyStatus}
                                                options={_.map(
                                                    [
                                                        'Warranty Machine',
                                                        'Extended Warranty Machine',
                                                        'Out of Warranty Machine',
                                                    ], i => ({ value: i, label: i })
                                                )}
                                                onChange={(j) => this.setState({ formData: { ...formData, warrantyStatus: j?.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AutoCompleteSelect 
                                                label="Description Of Work Template"
                                                value={formData.dowTemplate}
                                                options={lists.dowTemplates}
                                                onChange={(j) => this.setState({ formData: { ...formData, dowTemplate: j?.value, dow: j?.data?.jst_desc ? j?.data?.jst_desc : '' } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Textarea 
                                                label="Description Of Work"
                                                value={formData.dow}
                                                onChange={(e) => this.setState({ formData: { ...formData, dow: e.target.value } })}
                                                fullWidth
                                                rows={4}
                                            />
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect 
                                        label="Notes Template"
                                        value={formData.notesTemplate}
                                        options={lists.noteTemplates}
                                        onChange={(j) => this.setState({ formData: { ...formData, notesTemplate: j?.value, notes: j?.data?.jst_desc ? j?.data?.jst_desc : '' } })}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textarea 
                                        label="Additional Notes"
                                        value={formData.notes}
                                        onChange={(e) => this.setState({ formData: { ...formData, notes: e.target.value } })}
                                        fullWidth
                                        rows={4}
                                    />
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="outlined"
                            onClick={this.props.onClose}
                        >
                            Close 
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={()=>this.props.deployConfirmation(
                                'Are you sure you want to save this job sheet?',
                                'Save Job Sheet',
                                this.submit
                            )}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
                {!!dialog.showAddEngineer &&
                    <Dialog 
                        open={dialog.showAddEngineer}
                        onClose={()=>this.setState({ dialog: { ...dialog, showAddEngineer: false } })}
                    >
                        <DialogTitle>Add Engineer</DialogTitle>
                        <DialogContent>
                            <JobSheetEngineersForm 
                                onClose={()=>this.setState({ dialog: { ...dialog, showAddEngineer: false } })}
                                onSubmit={this.addEngineer}
                                companies={lists.companies}
                            />
                        </DialogContent>
                    </Dialog>
                }
            </Box>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff
    };
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
})

export default connect(mapStateToProps, mapDispatchToProps)(JobSheetForm);