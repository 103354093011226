import React from 'react';

import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import promise from 'redux-promise';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { Box, Grid, Button } from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

class JobSheetCompleteForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                orderId: null,
            },
            lists: {
                orders: [],
            },
            isLoading: true,
        };
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {
        API.get(`/customers/${this.props.jobSheet.customerId}/sales/orders`, {
            params: {
                addressId: this.props.jobSheet.addressId,
                active: 1
            },
        }).then(({data}) => {
            this.setState({
                lists: {
                    orders: data.map(o => ({
                        value: o.order_original_id,
                        label: `${o.order_reference}-${o.order_reference_amendment} - ${o.order_status}`,
                    })),
                },
                isLoading: false,
            });
        });
    }

    render() {
        const { formData, lists, isLoading } = this.state;

        if (isLoading) { return (<LoadingCircle/>) }

        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AutoCompleteSelect 
                            fullWidth
                            options={lists.orders}
                            label="Order"
                            value={formData.orderId}
                            onChange={e => this.setState({ formData: { ...formData, orderId: e?.value ? e.value : 0 } })}
                            disableSort
                        />
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="outlined"
                            onClick={this.props.onClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={()=>{
                                this.props.deployConfirmation(
                                    'Are you sure you want to set this job sheet as completed?',
                                    'Set Job Sheet as Completed',
                                    () => {
                                        API.post('/engineers/jobSheets/' + this.props.jobSheet.id + '/updateStatus', 
                                            {
                                                status: 'Complete',
                                                orderId: formData.orderId,
                                            })
                                        .then(() => {
                                            this.props.onClose();
                                            // this.props.onComplete();
                                        });
                                    }
                                )
                            }}
                        >
                            Set Completed
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (content, title, onConfirm) => dispatch(deployConfirmation(content, title, onConfirm))
});

export default connect(null, mapDispatchToProps)(JobSheetCompleteForm);