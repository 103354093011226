import React from 'react';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';
import API from 'API';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = (props={}) => ({
    formData: {
        id: props?.jst_id || 0,
        type: props?.jst_type || '',
        name: props?.jst_name || '',
        description: props?.jst_desc || '',
    },
});

class JobSheetTemplateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    save = () => {
        API.post(
            '/engineers/jobSheets/templates',
            this.state.formData
        ).then(()=>{
            this.props.onClose();
        });
    }

    render() {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AutoCompleteSelect 
                            fullWidth
                            label="Type"
                            options={[
                                { value: 'work', label: 'Description Of Work' },
                                { value: 'notes', label: 'Notes' },
                            ]}
                            value={this.state.formData.type}
                            onChange={(value) => this.setState({ formData: { ...this.state.formData, type: value.value } })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            label="Name"
                            value={this.state.formData.name}
                            onChange={(e) => this.setState({ formData: { ...this.state.formData, name: e.target.value } })}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Textarea 
                            label="Description"
                            value={this.state.formData.description}
                            onChange={(e) => this.setState({ formData: { ...this.state.formData, description: e.target.value } })}
                            fullWidth
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="outlined"
                            onClick={this.props.onClose}
                        >
                            Close
                        </Button>
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={()=>this.props.deployConfirmation(
                                'Are you sure you want to save this template?',
                                'Save Template',
                                this.save
                            )}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
});

export default connect(null, mapDispatchToProps)(JobSheetTemplateForm);