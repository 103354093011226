const adminRouteAccessReferences = {
    "/dashboard": "dashboard",
    /* Staff */
    "/staff/search": "staff-search",
    "/staff/add": "add-staff",
    "/staff/update": "update-staff",
    "/staff/access": "staff-access",
    "/staff/view": "view-staff",    
    "/staff/troll": "troll",    

    /* Parts */
    "/parts/numbering"                   : "part-numbering",
    "/parts/serial-numbers"              : "serial-numbers",
    "/parts/electrical-connections"      : 'electrical-connections',
    "/parts/electrical-connections/view" : 'electrical-connections-view',
    "/parts/electrical-connections/edit" : 'electrical-connections-edit',
    "/parts/add"                         : "add-part",
    "/parts/search"                      : "part-search",
    "/parts/view"                        : "view-part",
    "/parts/update"                      : "update-part",
    "/parts/warranty-terms"              : "warranty-terms",
    "/parts/categories"                  : "part-categories",
    "/parts/writeOffs/view"              : "works-orders:write-off",

    /* Documents */
    "/documents/categories"          : "documents-categories",
    "/documents/add"                 : "add-document",
    "/documents/search"              : "document-search",
    "/documents/view"                : "view-document",
    "/documents/update-order/add"    : "add-document-update-order",
    "/documents/update-order/search" : "document-update-order-search",
    "/documents/update-parts"        : "update-document-parts",
    "/documents/picking-parts"       : "update-document-parts",
    "/documents/update"              : "update-document",

    /* Calendar */
    "/calendar": "my-calendar",
    "/calendar/full": "full-calendar",
    "/calendar/events/add": "add-calendar-event",
    "/calendar/events/search": "search-calendar-events",
    "/calendar/events/update": "update-calendar-event",
    "/calendar/categories": "calendar-categories",
    /* R & D */
    "/research-development/change-order/add": "add-design-change-order",
    "/research-development/change-order/search": "design-change-order-search",
    "/research-development/part-files/add": "add-part-files",
    "/research-development/production-service-bulletin/add": "add-production-service-bulletin",
    "/research-development/production-service-bulletin/search": "production-service-bulletin-search",
    "/research-development/production-service-bulletin/update": "update-production-service-bulletin",

    /* Reports */
    "/reports/stock"                      : "stock-reports",
    "/reports/sales"                      : "sales-reports",
    "/reports/account"                    : "account-reports",
    "/reports/worksOrders"                : "works-order-reports",
    "/reports/part-stock-report"          : "part-stock-report",
    "/reports/overall-stock-report"       : "overall-stock-report",
    "/reports/minimum-stock-level-report" : "minimum-stock-level-report",
    "/reports/maximum-stock-level-report" : "maximum-stock-level-report",
    "/reports/parts-delivery-report"      : "parts-delivery-report",
    "/reports/masterExcel"                : "master-excel",
    "/reports/marketing"                  : "marketing-reports",
    "/reports/yoysales"                   : "yoys-reports",
    "/reports/performance"                : "performance-reports",

    /* Suppliers */
    "/suppliers/types/add": "add-supplier-type",
    "/suppliers/add": "add-supplier",
    "/suppliers/search": "supplier-search",
    "/suppliers/view": "view-supplier",
    "/suppliers/update": "update-supplier",
    /* Costs */
    "/costs/add": "add-cost",
    "/costs/search": "cost-search",
    "/costs/view": "view-cost",
    "/costs/update": "update-cost",
    "/costs/suppliers-reference/update": "update-supplier-reference",

    /* Sub Assemblies */
    "/sub-assemblies/add"               : "add-sub-assembly",
    "/sub-assemblies/update"            : "update-sub-assembly",
    "/sub-assemblies/view"              : "view-sub-assembly",
    "/sub-assemblies/history"           : "sub-assembly-history",
    "/sub-assemblies/search"            : "search-sub-assemblies",
    "/sub-assemblies/createBuildStages/": "update-sub-assembly",
    "/sub-assemblies/createBuildJigs/"  : "update-sub-assembly",

    /* Prices */
    "/prices/add"                        : "add-price",
    "/prices/search"                     : "price-search",
    "/prices/view"                       : "view-price",
    "/prices/update"                     : "update-price",
    "/prices/excel"                      : "price-excel",
    "/prices/spare-parts-calculator"     : "spare-parts-calculator",
    '/prices/part-categories-calculator' : 'part-categories-calculator',

    /* Library */
    "/library/parts/add": "add-part-image",
    "/library/parts/search": "part-image-search",
    "/library/parts/update": "update-part-image",
    "/library/category/add": "add-category-image",
    "/library/category/search": "category-image-search",
    "/library/category/update": "update-category-image",
    /* Videos */
    "/videos/categories": "video-categories",
    "/videos/add": "add-video",
    "/videos/search": "video-search",
    "/videos/view": "view-video",
    "/videos/update-order/add": "add-video-update-order",
    "/videos/update-order/search": "video-update-order-search",
    "/videos/update": "update-video",
    /* Supplier Stock Returns */
    "/supplier-stock-return/add": "add-supplier-stock-return",
    "/supplier-stock-return/search": "stock-return-search",
    "/supplier-stock-return/view": "view-stock-return",
    "/supplier-stock-return/approval": "stock-return-approval",
    "/supplier-stock-return/dispatch": "stock-return-dispatch",
    "/supplier-stock-return/confirmation": "stock-return-confirmation",
    "/supplier-stock-return/completed": "stock-return-completed",
    "/supplier-stock-return/declined": "stock-return-declined",
    "/supplier-stock-return/cancelled": "stock-return-cancelled",
    "/supplier-stock-return/credit": "stock-return-credit",
    "/supplier-stock-return/delivery": "stock-return-delivery",
    "/supplier-stock-return/delivery/update": "update-stock-return-delivery",
    "/supplier-stock-return/stock-return-email-templates": "stock-return-email-templates",
    "/supplier-stock-return/stock-return-email-templates/update": "update-stock-return-email-template",

    /* Stock */
    "/stock/opening-stock"             : "opening-stock",
    "/stock/activity"                  : "stock-activity",
    "/stock/min-max-levels"            : "min-max-stock-levels",
    "/stock/update"                    : "update-stock-levels",
    "/stock/build-checker"             : "build-checker",
    "/stock/print-all-labels"          : "print-all-stock-labels",
    "/stock/write-off/add"             : "add-stock-write-off",
    "/stock/write-off/default-reasons" : "stock-write-off-default-reasons",
    "/stock/write-off/search"          : "stock-write-off-search",
    "/stock/write-off/view"            : "view-stock-write-off",
    "/stock/additions/add"             : "add-stock-addition",
    "/stock/additions/default-reasons" : "stock-addition-default-reasons",
    "/stock/additions/search"          : "stock-addition-search",
    "/stock/additions/view"            : "view-stock-addition",
    "/stock/movement/reasons"          : 'stock-movement-reasons',
    "/stock/movement"                  : "stock-movement",
    "/stock/correction/edit"           : 'stock-correction:edit',
    "/stock/correction"                : "stock-correction",
    "/stock/take/new"                  : "add-stock-take",
    "/stock/take"                      : "stock-take",
    "/stock/low"                       : "stock:low-in-stock",
    "/stock/high"                      : "stock:high-in-stock",
    "/stock/tracker"                   : "stock:stock-tracker",
    "/stock/masterExcel"               : "stock-masterexcel",
    "/stock/blue-trolley"              : "blue-trolly",
    
    /* Works Order */
    "/WorksOrders/new"               : "works-orders:add",
    "/WorksOrders/view"              : "works-order:view",
    "/WorksOrders/shortages"         : "works-orders:shortage",
    "/WorksOrders/pick"              : "works-order:pick",
    "/WorksOrders/cancelled"         : "works-orders:cancelled",
    "/WorksOrders/completed"         : "works-orders:completed",
    "/WorksOrders/WorksOrderReasons" : "write-off-reasons",
    "/WorksOrders/all"               : "works-order:all",
    "/WorksOrders/builds"            : "works-order:all",
    '/WorksOrders/active'            : 'works-orders:active',

    /* Accounts */
    "/accounts/company-information"          : "company-information",
    "/accounts/nominal-codes/update"         : "update-nominal-code",
    "/accounts/nominal-codes"                : "nominal-codes",
    "/accounts/vat-rates"                    : "vat-rates",
    "/accounts/customer-discounts"           : "customer-discounts",
    "/accounts/customer-payment-terms"       : "customer-payment-terms",
    "/accounts/customer-account-statuses"    : "customer-account-statuses",
    "/accounts/add-bank-account"             : "add-bank-account",
    "/accounts/bank-transactions"            : "bank-transactions",
    "/accounts/unassigned-bank-transactions" : "unassigned-bank-transactions",
    "/accounts/bank-search"                  : "bank-search",
    "/accounts/bank-payments"                : "bank-payments",
    "/accounts/bank-running-totals"          : "bank-running-totals",
    "/accounts/customer-credit-statements"   : 'customer-credit-statements',
    "/accounts/customer-payment-requests"    : 'customer-payment-requests',

    /* Parts Ordering */  
    "/parts-order/email-template/update"    : "update-parts-order-email-template",
    "/parts-order/email-template"           : "parts-order-email-template",
    "/parts-order/default-list/update"      : "update-default-parts-order-list",
    "/parts-order/default-list"             : "default-parts-order-list",
    "/parts-order/default-address/update"   : "update-default-parts-order-address",
    "/parts-order/default-addresses"        : "parts-order-default-addresses",
    "/parts-order/delivery/change-schedule" : "update-parts-order-delivery-schedule",
    "/parts-order/delivery/confirm"         : "confirm-parts-order-delivery",
    "/parts-order/delivery/update"          : "update-parts-order-delivery",
    "/parts-order/approval"                 : "parts-order-approval",
    "/parts-order/declined"                 : "parts-order-declined",
    "/parts-order/cancelled"                : "parts-order-cancelled",
    "/parts-order/completed"                : "parts-order-completed",
    "/parts-order/confirmation"             : "parts-order-confirmation",
    "/parts-order/search"                   : "parts-order-search",
    "/parts-order/add"                      : "add-parts-order",
    "/parts-order/delivery"                 : "parts-order-delivery",
    "/parts-order/invoice"                  : "parts-order-invoice",
    "/parts-order/view"                     : "view-parts-order",
    "/parts-order/other-items/update"       : "update-parts-order-other-items",
    "/parts-order/tracker"                  : "procurement-tracker",
    "/parts-order/3dtracker"                : "threed-printing-tracker",

    /* Puchase Ordering */
    "/purchase-order/email-template/update" : "update-purchase-order-email-template",
    "/purchase-order/email-template" : "purchase-order-email-template",
    "/purchase-order/default-list/update" : "update-default-purchase-order-list",
    "/purchase-order/default-list" : "default-purchase-order-list",
    "/purchase-order/default-address/update" : "update-default-purchase-order-address",
    "/purchase-order/default-addresses" : "purchase-order-default-addresses",
    "/purchase-order/approval" : "purchase-order-approval",
    "/purchase-order/declined" : "purchase-order-declined",
    "/purchase-order/cancelled" : "purchase-order-cancelled",
    "/purchase-order/completed" : "purchase-order-completed",
    "/purchase-order/confirmation" : "purchase-order-confirmation",
    "/purchase-order/search" : "purchase-order-search",
    "/purchase-order/add" : "add-purchase-order",
    "/purchase-order/delivery" : "purchase-order-delivery",
    "/purchase-order/invoice" : "purchase-order-invoice",
    "/purchase-order/view" : "view-purchase-order",
    "/purchase-order/update" : "update-purchase-order",
    /* Email */
    "/email/email-template/update" : "update-email-template",
    "/email/email-templates" : "email-templates",
    "/email/send-files" : "send-files",
    "/email/search" : "search-emails",
    "/email/view" : "view-email",
    "/email/email-folders" : "email-folders",

    /* Engineers */
    "/engineers/add"             : "add-engineer",
    "/engineers/search"          : "search-engineers",
    "/engineers/view"            : "view-engineer",
    "/engineers/update"          : "update-engineer",
    "/engineers/uploads/add"     : "add-engineer-uploads",
    "/engineers/uploads/search"  : "engineer-uploads-search",
    "/engineers/maps"            : "engineer-view-maps",
    "/engineers/jobs/view"       : "view-engineer-job",
    "/engineers/jobs/search"     : "engineer-jobs-search",
    "/engineers/email-templates/update" : "engineer-email-templates:manage",
    "/engineers/email-templates" : "engineer-email-templates",
    "/engineers/jobsheets/templates" : "engineer-job-sheet-templates",
    "/engineers/jobsheets/engineers" : "engineer-job-sheet-engineers",
    "/engineers/jobsheets" : "engineer-job-sheets",

    /* Equipment */
    "/equipment/search"             : "equipment-search",
    "/equipment/view"               : "view-equipment",
    "/equipment/add"                : "add-equipment",
    "/equipment/reasons"            : "equipment-reasons",

    /* Forms */
    "/forms/search"                 : "forms-search",
    "/forms/view"                   : "forms-search:view-form",
    "/forms/related"                : "forms-related",
    "/forms/categories"             : "forms-categories",
    "/forms/master-elements"        : "forms-master-elements",
    
    /* Vehicles */
    "/vehicles/add"                 : "add-vehicle",
    "/vehicles/search"              : "search-vehicles",
    "/vehicles/view"                : "view-vehicle",
    "/vehicles/update"              : "update-vehicle",
    "/vehicles/insurance/search"    : "search-vehicle-insurance",
    "/vehicles/devices/search"      : "search-vehicle-devices",
    "/vehicles/breakdown/search"    : "search-vehicle-breakdown",

    /* Customers */
    "/customers/contacts/update":  "update-customer-contact",
    "/customers/contacts/add":     "add-customer-contact",
    "/customers/contacts/view":    "view-customer-contact",
    "/customers/addresses/add":    "add-customer-address",
    "/customers/addresses/view":   "view-customer-address",
    "/customers/addresses/update": "update-customer-address",
    "/customers/add":              "add-customer",
    "/customers/search":           "customer-search",
    "/customers/view":             "view-customer",
    "/customers/update":           "update-customer",
    "/customers/quotation/search": "customer-quotation-search",
    "/customers/order/search":     "customer-order-search",
    "/customers/access":           "customer-access",
    "/customers/emails":           "customer-emails",
    "/customers/map":             "customer-map",

    /* Customer Returns */
    "/returns/creditnote/add"             : "add-credit-note",
    "/returns/creditnote/search"          : "search-credit-note",
    '/returns/creditnote/approval'        : 'approve-credit-note',
    '/returns/creditnote/confirm'         : 'confirm-credit-note',
    '/returns/creditnote/decline'         : 'decline-credit-note',
    '/returns/creditnote/cancel'          : 'cancel-credit-note',
    '/returns/creditnote'                 : 'credit-note',
    '/returns/email-template'             : 'customer-returns-email-templates',
    '/returns/legal-documents'            : 'customer-returns-legal-documents',
    '/returns/greenaware'                 : 'greenaware',
    '/returns/add'                        : 'returns-add-return',
    '/returns/edit'                       : 'returns-edit-return',
    '/returns/customerReturn/approval'    : 'returns-approval',
    '/returns/customerReturn/confirm'     : 'returns-confirm',
    '/returns/customerReturn/collection'  : 'returns-collection',
    '/returns/customerReturn'             : 'view-return',
    '/returns/customerReturn/pick'        : 'returns-collectionPick',
    '/returns/customerReturn/review'      : 'returns-review',
    '/returns/customerReturn/inspection'  : 'returns-inspection',
    '/returns/customerReturn/repair'      : 'returns-repair',
    '/returns/customerReturn/search'      : 'returns-search',
    '/returns/customerReturn/default'     : 'returns-default',

    /* Sales */
    "/sales/quotation/add":                  "add-quotation",
    "/sales/quotation/search":               "quotation-search",
    "/sales/quotation/view":                 "view-customer-quotation",
    "/sales/quotation/amend":                "amend-customer-quotation",
    "/sales/legal-documents":                "sales-legal-documents",
    "/sales/order/add":                      "add-order",
    "/sales/order/search":                   "order-search",
    "/sales/order/approval":                 "order-approval",
    "/sales/order/confirmed":                "order-confirmed",
    "/sales/order/payment":                  "order-payment",
    "/sales/order/picking":                  "order-picking",
    "/sales/order/dispatch":                 "order-dispatch",
    "/sales/order/dispatch/view":            "view-dispatch",
    "/sales/order/invoice":                  "order-invoice",
    "/sales/order/invoice/approval":         "invoice-approval",
    "/sales/order/invoice/payments":         "invoice-payments",
    "/sales/order/commercial-invoice/add":   "add-commercial-invoice",
    "/sales/order/commercial-invoice/amend": "amend-commercial-invoice",
    "/sales/order/completed":                "order-completed",
    "/sales/order/declined":                 "order-declined",
    "/sales/order/cancelled":                "order-cancelled",
    "/sales/order/view":                     "view-customer-order",
    "/sales/order/amend":                    "amend-customer-order",
    "/sales/email-template":                 "sales-email-template",
    "/sales/order/invoice/recurring":        "recurring-invoice",
    "/sales/order/invoice/recurring/edit/":  "amend-recurring-invoice",
    "/sales/package":                        "sales-package",
    "/sales/package/add":                    "add-sales-package",
    "/sales/package/edit":                   "edit-sales-package",
    
    /* Tasks & Reminders */
    "/tasks/add": "add-task",
    "/tasks": "tasks",
    "/reminders/add": "add-reminder",
    "/reminders": "reminders",
    /* Couriers */
    "/courier/add": "add-courier",
    "/courier/search": "courier-search",
    "/courier/update": "update-courier",
    "/courier/view": "view-courier",
    /* Meetings */
    "/meetings/new": "new-meeting",
    "/meetings/search": "meeting-search",
    
    /* Marketing */
    "/marketing/shortlinks/add":    "add-shortlink",
    "/marketing/shortlinks/search": "shortlink-search",
    "/marketing/campaigns":         "marketing-campaigns",
    "/marketing/customerMarketing": "customer-marketing",
    "/marketing/contactsexcel":     "contacts-excel",
    "/marketing/emailValidation":   "email-validation",
    "/marketing/mailingLists":      "mailing-lists",
    "/marketing/manualContacts":    "manual-contacts",

    /* E-Commerce */
    "/ecommerce/product-categories": "ecommerce-product-categories",
    "/ecommerce/products/add": "add-ecommerce-product",
    "/ecommerce/products/search": "ecommerce-product-search",
    "/ecommerce/products/view": "view-ecommerce-product",
    "/ecommerce/product-categories/update": "update-ecommerce-product-category",
    "/ecommerce/products/update": "update-ecommerce-product",
    /* Phone */
    "/phone/call-history": "call-history",
    "/phone/global-phone-book": "global-phone-book",
    /* ToolBox */ 
    "/toolbox/quickQr":    "toolbox-quick-qr",
    "/toolbox/findStock":  "toolbox-find-stock",
    "/toolbox/reordernav": "toolbox-re-order-nav",
    "/toolbox/printing":   "toolbox-printing",
    "/toolbox/processes":   "toolbox-processes",

    // /* Performance */
    "/performance/targets": "performance-targets",

    /* Survey */
    "/survey": "survey",

    /*
     * Automatic access
     */
    "/my-account": true,
    "/offline": true     
};
export default adminRouteAccessReferences;