import API from 'API';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import { coActivityLog, colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { getMediaType } from 'Helpers/FileHelper';
import icons from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

import BackButton from 'Components/Common/Buttons/BackButton';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import EmailDialog from 'Components/Common/Dialogs/EmailDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import TabBar from 'Components/Common/Navigation/TabBar';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import PDFViewer from 'Components/Common/PDFViewer/PDFViewer';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import StickyNotes from 'Components/Common/StickyNotes/StickyNotes';
import TypographyAllowHtml from 'Components/Common/Typography/TypographyAllowHtml';
import FileDialog from 'Components/Sales/Order/AddFileDialog/AddFileDialog';
import EngineerMediaDialog from 'Components/Sales/Order/AddFileDialog/EngineerMediaDialog';
import Order from 'Components/Sales/Order/ViewOrder/Order';
import StaffActions from 'Components/Sales/Order/ViewOrder/sections/StaffActions';

import NoteBlock from './../../NoteBlock';
import EngineerMedia from 'Components/Common/EngineerMedia/ViewEngineerMedia';
import Greenaware    from './sections/Greenaware';
import PickingFiles  from './sections/PickingFiles';
import CustomerReturns from './sections/CustomerReturns';
import ReplacementFor from './sections/ReplacementFor';

import { Box, Button, Card, CardContent, CardHeader, Chip, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, 
    ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Paper, Step, 
    StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@material-ui/core';
import MuiLink        from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles';


import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { downloadS3File } from 'Functions/MiscFunctions';
import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import 'Assets/timeLine.css';

import AddEngineerMedia from 'Components/Common/EngineerMedia/AddEngineerMedia';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import ViewEmails from 'Components/Common/Email/ViewEmails/ViewEmails';
import RepairOrder from './sections/RepairOrder';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';


const initialState = () => ({
    formData: {
        noteType: '',
        notesText: '',
        fileType: '',
        file: ''
    },
    formErrors: [],
    currentTab: 'order',
    co: {},
    files: [],
    commercialInvoices: [],
    orderId: null,
    redirect: false,
    emailDialogOpen: false,
    isLoading: true,
    snackbarOpen: false,
    succesMsg: '',
    dialogCloseOpen: false,
    snackbarCloseOpen: false,
    dialogOrderOpen: false,
    snackbarOrderOpen: false,
    dialogNotesOpen: false,
    dialogCourierData: {},
    dialogCourierOpen: false,
    dialogPaymentOpen: false, 
    dialogPaymentData: {},
    snackbarNotesOpen: false,
    quotationNotifications: [],
    statuses: [],
    activeStep: 0,
    enablePayment: false,
    enableDespatch: false,
    expanded: {},
    confirmNoteDelete : 0,
    access:             {},
    showPushBackOrder:  false,
    pushBackFormData:   {
        reason: ' ',
        removePayment: 0
    },
    orderLocked:    true,
    dialogUploadOpen: false, 
    dialogUploadData: {
        type: null,
        file: null,
        id:   0
    },
    dialogUploadFormData: {
        file: null
    }
});

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    btnRow: {
        textAlign: 'center', 
    },
    btnRowBtn: {   
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        stepper: {
            display: 'none',
        },
    },
    statusHeading: {
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        color: 'black'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tabScrollButtons: {
        color: 'black'
    }
});

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

const PaymentDialog  = ({rowData, closeDialog}) => {
    return (
        <>
            <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} style={{color: 'black'}}>Refund Date</InputLabel>
                <Input value={moment(rowData.cnp_refund_date).format('DD/MM/YYYY')} disabled />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} style={{color: 'black'}}>Method</InputLabel>
                <Input value={rowData.cnp_method} disabled />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} style={{color: 'black'}}>Refund Amount</InputLabel>
                <Input value={`£${rowData.cnp_amount}`} disabled />
            </FormControl>
            {rowData.cnp_method == 'Bank Transfer' ? 
                <>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel shrink={true} style={{color: 'black'}}>Refund Ref</InputLabel>
                        <Input value={rowData.cnp_refund_ref} disabled />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel shrink={true} style={{color: 'black'}}>Payee</InputLabel>
                        <Input value={rowData.cnp_payee} disabled />
                    </FormControl>
                </>
                :
                <>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel shrink={true} style={{color: 'black'}}>Refund Ref</InputLabel>
                        <Input value={rowData.cnp_refund_ref} disabled />
                    </FormControl>
                    {rowData.cnp_auth_no &&
                        <FormControl margin="normal" fullWidth>
                            <InputLabel shrink={true} style={{color: 'black'}}>Auth No</InputLabel>
                            <Input value={rowData.cnp_auth_no} disabled />
                        </FormControl>
                    }
                </>
            }
            <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} style={{paddingBottom: '1.8em', color: 'black'}}>Refund File</InputLabel>
                <TextField
                    style={{marginTop: '0.7em'}}
                    fullWidth
                    value={rowData.file_upload ? _.last(rowData.file_upload.split('/')) : 'No File'}
                    disabled
                    InputProps={{
                        endAdornment: rowData.file_upload && (<AllIcon icon={icons.search} onClick={this.viewFile(rowData.file_upload)} noMargin />)
                    }}
                />
            </FormControl>
            {rowData?.cnp_file && <ImageWithError src={rowData?.cnp_file} style={{height:200, maxWidth: '100%'}} />}
            <div className="buttonRow">
                <Button 
                    variant="outlined" 
                    color="default" 
                    onClick={closeDialog}
                >
                    Close
                </Button>
            </div>
        </>
    )
}

class ViewOrder extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `Order:View:${this.props.id ? this.props.id : this.props.match.params.id}`;
        this.state              = this.getInitialState(initialState());
    }
    componentDidMount(){
        this.getAccess();
        this.setState({
            orderId: this.props.id ? this.props.id : this.props.match.params.id
        },()=>this.getLatestOrderId());
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/add-credit-note'), 
            API.get('/staff/my/access/check/sales-order-create-sticky-note'), 
            API.get('/staff/my/access/check/sales-order-view-sticky-note'),
            API.get('/staff/my/access/check/push-back-customer-order'),
            API.get('/staff/my/access/check/returns-add-return'),
            API.get('/staff/my/access/check/repeat-customer-order')
        ])
        .then(([creditNoteRes, createStickyNoteRes, viewStickyNoteRes, pushBackRes, customerReturnRes, repeatOrderRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    creditNote:             creditNoteRes.data.has_access       || false,
                    createStickyNoteRes:    createStickyNoteRes.data.has_access || false,
                    viewStickyNoteRes:      viewStickyNoteRes.data.has_access   || false,
                    pushBack:               pushBackRes.data.has_access         || false,
                    customerReturn:         customerReturnRes.data.has_access   || false,
                    repeatOrder:            repeatOrderRes.data.has_access      || false
                }
            });
        })
    }

    getLatestOrderId = () => {
        API.get('/sales/orders/' + this.state.orderId + '/mostRecentCOLinked')
        .then(result => {
            if(result.data.latestOrderId !== parseInt(this.state.orderId)){
                window.location.href = `/sales/order/view/${result.data.latestOrderId}`;
            } else {
                this.getOrder();
            }
        });
    }
    getOrder = (callback = null) => {
        this.setState({
            orderId: this.props.id ?? this.props.match.params.id
        }, () => {
            API.get('/sales/orders/' + this.state.orderId)
            .then(result => {
                if(result.data) {
                    let enablePayment = result.data?.payment_term?.payment_term_upfront_payment;
                    let enableDespatch = result.data?.order_despatch_date && result.data?.order_despatch_date != '0000-00-00' ? true : false;
                    let enablenoDespatch = !enableDespatch && _.filter(result.data.despatch, i => i.desp_reference.includes('/MD/')).length > 0 ? true : false;

                    // PROGRESS STEPS
                    let statuses = ['Awaiting Approval', 'Confirmed'];
                    if(enablePayment){
                        statuses.push('Awaiting Payment', 'Payment Confirmed');
                    }
                    if(enableDespatch){
                        statuses.push('Picking', 'Awaiting Despatch');
                    }
                    if (enablenoDespatch){
                        enableDespatch = true;
                    }
                    statuses.push('Invoice', 'Completed');

                    const activeStep = _.findIndex(statuses, function(el) { return el === result.data.order_status; });

                    // ACTIVITY
                    _.map(result.data.activity, (log) => {
                        let data             = coActivityLog(log.order_log_type);
                        log.color            = data['color'];
                        log.icon             = data['icon'];
                        log.time             = moment(log.order_log_datetime_added).format("DD/MM/YYYY");
                    });

                    // FILES
                    let files = [];

                    _.map(result.data.files, file => {

                        file.version = parseInt(file.co_file_filename?.substring(8,9)); 

                        switch(file.co_file_type) {
                            case 'Purchase Order':
                                if(!files['PurchaseOrder']) files['PurchaseOrder'] = [];
                                files['PurchaseOrder'].push(file);
                            break;
                            case 'Courier Despatch Note':
                                if(!files['CourierDespatchNote']) files['CourierDespatchNote'] = [];
                                files['CourierDespatchNote'].push(file);
                            break;
                            case "Engineer Job Sheet":
                                if(!files['EngineerJobSheet']) files['EngineerJobSheet'] = [];
                                files['EngineerJobSheet'].push(file);
                            break;
                            case 'Payment Confirmation':
                                if(!files['PaymentConfirmation']) files['PaymentConfirmation'] = [];
                                files['PaymentConfirmation'].push(file);
                            break;
                            case 'Call Recording':
                                if(!files['Call Recording']) files['Call Recording'] = [];
                                files['Call Recording'].push(file);
                            break;
                            default:
                        }
                    });
                    // Commercial Invoices
                    let commercialInvoices = [];
                    _.map(result.data.commercial_invoices, ci => {
                        if(!commercialInvoices[ci.commercial_invoice_number]) commercialInvoices[ci.commercial_invoice_number] = [];
                        commercialInvoices[ci.commercial_invoice_number].push(ci);
                    });
                    
                    if (result.data.invoice_payment_terms){
                        let payment         = _.sumBy(result.data.invoices, i => i.sum_of_payments) || 0;
                        let invoicePayments = [];
                        let i               = 1;
                        const paymentDays   = [0,7,14,30,0,0,0,0,60,90,0,0,0];
                        const days          = paymentDays[result.data.invoice_payment_terms.oipt_sub_payment_term_id];
                        const now           = moment();
                        for(i;i < (result.data.invoice_payment_terms.oipt_payments + 1); i++) {

                            let status = 'TBC';
                            let icon   = icons.waiting;
                            let to     = moment(_.first(result.data.invoices).invoice_date).add(days * i, 'days');
                            let from   = moment(_.first(result.data.invoices).invoice_date).add(days * (i-1), 'days');
                            let credit = parseFloat(result.data.invoice_payment_terms.oipt_payment_amount) * i;

                            if (now.isAfter(from)){
                                status = (now.isBefore(to)) ? 'due' : 'overdue';
                                icon   = (now.isBefore(to)) ? icons.due : icons.overdue;
                            }

                            if (payment >= credit) {
                                status = 'complete';
                                icon   = icons.complete;
                            }

                            invoicePayments.push({to, from, status, icon});
                        }
                        result.data.invoicePayments = invoicePayments;
                    }                    
                    this.setState({
                        co: result.data,
                        files,
                        commercialInvoices,
                        isLoading: false,
                        statuses,
                        activeStep,
                        enablePayment,
                        enableDespatch
                    }, () => {
                        if(callback) {
                            callback();
                        }
                    });
                }
                else {
                    window.location.href = `/sales/order/search`;
                }
            });
        });
    }
    downloadCO = (order_id, view=true) => {
        API.get('/sales/orders/' + order_id + '/pdf?preview=1')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, /*result.data.pdfName*/ 'customer_order.pdf');
            }
        });
    }
    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        }, () => this.savePageState());
    };
    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    }
    // EMAIL
    handleEmailOpen = () => {
        let dialogType = '';
        switch(this.state.co.order_status) {
            case 'Declined':
            case 'Cancelled':
                dialogType = 'warning';
            break;
            default:
                dialogType = 'success';
            break;
        }
        const dialog = <EmailDialog
                            customerId={this.state.co.order_cust_id}
                            customerContactId={this.state.co.order_cust_contact_id}
                            type={this.generateEmailType()}
                            id={this.state.co.order_id}
                        />
        const title = this.generateDialogTitle();
        this.props.deployDialog(dialog, title, dialogType);
    }
    generateDialogTitle = () => {
        let title = '';
        switch(this.state.co.order_status) {
            case 'Awaiting Approval':
                title = 'Order Acknowledgment';
            break;
            case 'Confirmed':
                title = 'Order Confirmation';
            break;
            case 'Awaiting Payment':
                title = 'Order Awaiting Payment';
            break;
            case 'Payment Confirmed':
                title = 'Order Payment Confirmed';
            break;
            case 'Picking':
                title = 'Order Awaiting Picking';
            break;
            case 'Awaiting Despatch':
                title = 'Order Awaiting Despatch';
            break;
            case 'Invoice':
                title = 'Order Awaiting Invoice';
            break;
            case 'Cancelled':
                title = 'Order Cancelled Confirmation';
            break;
            case 'Declined':
                title = 'Order Declined Confirmation';
            break;
            default:
                title = 'Order';
            break;
        }

        return `You Are About To Email - ${title}`;
    }
    generateEmailType = () => {
        let type = '';
        switch(this.state.co.order_status) {
            case 'Awaiting Approval':
                type = 'acknowledgement';
            break;
            case 'Confirmed':
                type = 'confirmation';
            break;
            case 'Awaiting Payment':
                type = 'awaiting-payment';
            break;
            case 'Payment Confirmed':
                type = 'payment-confirmed';
            break;
            case 'Cancelled':
                type = 'cancelled';
            break;
            case 'Declined':
                type = 'declined';
            break;
            default:
            break;
        }

        return type;
    }
    // NOTES
    handleNotesOpen = () => {this.setState({dialogNotesOpen: true});};
    handleNotesClose = () => {this.setState({dialogNotesOpen: false});};
    handleNotesSuccess = () => {
        this.setState({ dialogNotesOpen: false });
        this.notesSubmit();
    }
    handleNotesSnackbarClose = () => {this.setState({ snackbarNotesOpen: false }); };
    notesSubmit = () => {
        API.post(`/sales/orders/${this.state.orderId}/notes`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogNotesOpen: true
                });
            }
            else {
                this.setState({
                    snackbarNotesOpen   : true,
                    formData            : initialState().formData
                }, () => {
                    this.softUpdate();
                });
            }
        });
    }
    // FILES
    handleAddFiles = () => {
        const dialog = <FileDialog
                            orderId={this.state.co.order_id}
                            order={this.state.co}
                            getOrder={this.getOrder}
                            despatch={this.state.co?.despatch}
                        />
        this.props.deployDialog(dialog, 'Add Files', '', 'sm');
    }

    handleAddEngineerMedia = () => {
        this.props.deployDialog(<AddEngineerMedia 
            onSubmit={this.getOrder} 
            siteId={this.state.co.order_delivery_cust_address_id}
            orderId={this.state.co.order_id}
            customerId={this.state.co.order_cust_id}
        />, 'Add Media');
    }

    // CLOSE
    handleCloseOpen = () => {this.setState({dialogCloseOpen: true});};
    handleCloseClose = () => {this.setState({dialogCloseOpen: false});};
    handleCloseSuccess = () => {
        this.setState({ dialogCloseOpen: false });
        this.closeSubmit();
    }
    handleCloseSnackbarClose = () => {this.setState({ snackbarCloseOpen: false }); };
    closeSubmit = () => {
        API.post(`/sales/orders/${this.state.orderId}/cancel`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogCloseOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarCloseOpen: true
                }, () => {
                    this.getOrder(this.handleEmailOpen);
                });
            }
        });
    }
    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false }); 
    };
    // COURIER DETAILS
    handleCourierOpen = (rowData) => {
        rowData.despatchNotes = _.filter(this.state.files?.CourierDespatchNote, {'co_file_despatch_id': rowData.desp_id})
               
        this.setState({
            dialogCourierOpen: true, 
            dialogCourierData: rowData
        });
    };
    handleCourierClose = () => {this.setState({dialogCourierOpen: false});};
    
    // PAYMENT DETAILS
    handleUploadOpen = (rowData, type) => () => {
        this.setState({
            dialogUploadOpen: true, 
            dialogUploadData: {
                type: type,
                file: rowData.file_upload,
                id: rowData[type == 'order' ? 'op_id' : 'ip_id']
            },
            dialogUploadFormData: {
                file: ''
            }
        });
    }

    handleUploadClose = () => {this.setState({dialogUploadOpen: false});};

    handlePaymentOpen = (rowData, type) => {
        let dialogPaymentData = {}

        if(type == 'order'){
            dialogPaymentData.paymentDate = rowData.op_payment_datetime;
            dialogPaymentData.paymentMethod = rowData.op_txn_method;
            dialogPaymentData.paymentAmount = rowData.op_txn_amount;
            // Bank Transfer
            dialogPaymentData.bankTransferRef = rowData.op_bank_transfer_ref;
            dialogPaymentData.bankTransferPayee = rowData.op_bank_transfer_payee;
            // Cheque
            dialogPaymentData.chequeNumber = rowData.op_cheque_no;
            dialogPaymentData.chequePayee  = rowData.op_cheque_payee;
            //dialogPaymentData.file         = rowData.op_cheque_image;
            // Card
            dialogPaymentData.cardPaymentRef = rowData.op_card_payment_ref;
            dialogPaymentData.cardAuthNumber = rowData.op_card_auth_no;
            //dialogPaymentData.file           = rowData.op_card_epdq_img && rowData.op_card_epdq_img !== '' ? rowData.op_card_epdq_img : dialogPaymentData.file;
            // PayPal
            if(rowData.op_paypal_shipping_address && rowData.op_paypal_shipping_address != 'null'){
                let paypalAddress = JSON.parse(rowData?.op_paypal_shipping_address);
                dialogPaymentData.paypalShippingAddress =   paypalAddress.address_line_1 + '\n' + 
                                                            paypalAddress.address_line_2 + '\n' + 
                                                            paypalAddress.admin_area_1 + '\n' + 
                                                            paypalAddress.admin_area_2 + '\n' + 
                                                            paypalAddress.postal_code + '\n' + 
                                                            paypalAddress.country_code
            }
            // Stripe
            dialogPaymentData.stripeCardType = rowData.op_stripe_card_type;
            dialogPaymentData.stripeCardNumber = rowData.op_stripe_card_number;
            dialogPaymentData.file = rowData.file_upload;
        } 
        else if(type == 'invoice'){
            dialogPaymentData.paymentDate = rowData.ip_payment_datetime;
            dialogPaymentData.paymentMethod = rowData.ip_txn_method;
            dialogPaymentData.paymentAmount = rowData.ip_txn_amount;
            // Bank Transfer
            dialogPaymentData.bankTransferRef = rowData.ip_bank_transfer_ref;
            dialogPaymentData.bankTransferPayee = rowData.ip_bank_transfer_payee;
            // Cheque
            dialogPaymentData.chequeNumber = rowData.ip_cheque_no;
            dialogPaymentData.chequePayee = rowData.ip_cheque_payee;
            dialogPaymentData.chequeImage = rowData.ip_cheque_image;
            // Card
            dialogPaymentData.cardPaymentRef = rowData.ip_card_payment_ref;
            dialogPaymentData.cardAuthNumber = rowData.ip_card_auth_no;
            dialogPaymentData.cardEpdqImage = rowData.ip_card_epdq_img;
            // PayPal
            if(rowData.ip_paypal_shipping_address && rowData.ip_paypal_shipping_address != 'null'){
                let paypalAddress = JSON.parse(rowData?.ip_paypal_shipping_address);
                dialogPaymentData.paypalShippingAddress =   paypalAddress.address_line_1 + '\n' + 
                                                            paypalAddress.address_line_2 + '\n' + 
                                                            paypalAddress.admin_area_1 + '\n' + 
                                                            paypalAddress.admin_area_2 + '\n' + 
                                                            paypalAddress.postal_code + '\n' + 
                                                            paypalAddress.country_code
            }
            // Stripe
            dialogPaymentData.stripeCardType = rowData.ip_stripe_card_type;
            dialogPaymentData.stripeCardNumber = rowData.ip_stripe_card_number;
            // Direct Debit
            dialogPaymentData.directDebitRef = rowData.ip_direct_debit_ref;
            //foc
            dialogPaymentData.focReason = rowData.ip_foc_reason;

            dialogPaymentData.file = rowData.file_upload;
        }
        this.setState({
            dialogPaymentOpen: true, 
            dialogPaymentData
        });
    };
    handlePaymentClose = () => {this.setState({dialogPaymentOpen: false});};

    handleDownload = (orderId, id) => {
        API.post(`/sales/orders/${orderId}/prevPdf`, {
            'type': 'delivery-note', 
            'id': id
        })
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleDownloadInvoice = (invoiceId) => {
        API.post(`/sales/orders/invoice/${invoiceId}/pdf`)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleDownloadCreditNoteInvoice = (invoiceId) => {
        API.post(`/customerReturns/creditNote/invoice/${invoiceId}/prevPdf`, {type: 'Invoice'})
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleDownloadCommercialInvoice = (commercialId) => {
        API.post(`/sales/orders/commercialInvoice/${commercialId}/pdf`)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleExpandInvoices = (idx) => {
        this.setState({
            expanded: {
                ...this.state.expanded,
                [idx]: !this.state.expanded[idx]
            }
        })
    }

    handleDeleteNote = (id) => {
        this.setState({
            confirmNoteDelete : id
        });
    }
    handleCloseDeleteNote = () => {
        this.setState({
            confirmNoteDelete : 0
        });
    }
    deleteNote = () => {
        API.post(`/misc/notes/${this.state.confirmNoteDelete}/delete`, {type : "order-notes"})
            .then(result => {
                if (!result.data.error) this.softUpdate()
                this.setState({
                    confirmNoteDelete : 0
                });
            })
        this.handleCloseDeleteNote();
    }
    handleCourierOpen = (rowData) => {
        rowData.despatchNotes = _.filter(this.state.files?.CourierDespatchNote, {'co_file_despatch_id': rowData.desp_id})
               
        this.setState({
            dialogCourierOpen: true, 
            dialogCourierData: rowData
        });
    };

    softUpdate = () => {
        Promise.all([
            API.get(`/sales/orders/${this.state.orderId}/notes`),
            API.get(`/sales/orders/${this.state.orderId}/activityLog`),
            API.get(`/sales/orders/${this.state.orderId}/files`)
        ]).then(([notes, acl, filesres]) => {
            if (!notes.data.errors && !acl.data.errors && !filesres.data.errors) {
                _.map(acl.data.activityLog, (log) => {
                    let data = coActivityLog(log.order_log_type);
                    log.color = data['color'];
                    log.icon = data['icon'];
                    log.time = moment(log.order_log_datetime_added).format("DD/MM/YYYY");
                });

                // FILES
                let files = [];
                _.map(filesres.data, file => {
                    file.version = parseInt(file.co_file_filename?.substring(8,9)); 
                    switch(file.co_file_type) {
                        case 'Purchase Order':
                            if(!files['PurchaseOrder']) files['PurchaseOrder'] = [];
                            files['PurchaseOrder'].push(file);
                        break;
                        case 'Courier Despatch Note':
                            if(!files['CourierDespatchNote']) files['CourierDespatchNote'] = [];
                            files['CourierDespatchNote'].push(file);
                        break;
                        case "Engineer Job Sheet":
                            if(!files['EngineerJobSheet']) files['EngineerJobSheet'] = [];
                            files['EngineerJobSheet'].push(file);
                        break;
                        case 'Payment Confirmation':
                            if(!files['PaymentConfirmation']) files['PaymentConfirmation'] = [];
                            files['PaymentConfirmation'].push(file);
                        break;
                        case 'Call Recording':
                            if(!files['Call Recording']) files['Call Recording'] = [];
                            files['Call Recording'].push(file);
                        break;
                        default:
                    }
                });
                this.setState({
                    co : {
                        ...this.state.co,
                        notes       : notes.data,
                        activity    : acl.data.activityLog,
                    },
                    files
                }, () => {
                    if (this.state.dialogCourierOpen){
                        this.handleCourierOpen(this.state.dialogCourierData);
                    }
                })
            }
        } )
    }

    deleteFile = () => {
        API.post(`/sales/orders/${this.state.orderId}/files/${this.state.delFileId}/delete`)
        .then( (res) => {
            if (!res.data.error){
                this.handleDeleteFileConfirmation();
                this.softUpdate();
            }
        } )
    
    }

    handleDeleteFileConfirmation = (delFileId = 0) => {
        this.setState({
            confirmFileDelete: !this.state.confirmFileDelete,
            delFileId
        });
    }

    getTabs = () => {
        const {enableDespatch, co} = this.state;
        let ret = [
            {name: 'order',     label: 'Order'},
            {name: 'notes',     label: `Notes (${co.notes?.length})`},
            {name: 'acl',       label: 'Activity Log'},
            {name: 'payments',  label: 'Payments'},
        ]
        //if (enableDespatch && _.filter(co.despatch, i => !i.desp_reference.includes('/MD/')).length > 0) ret.push({name: 'picking',  label: 'Picking'});
        ret.push({name: 'picking',  label: 'Picking'});
        if (enableDespatch) ret.push({name: 'despatch', label: 'Despatch'});


        ret.push({name: 'img',  label: 'Media'})
        ret.push({name: 'inv',  label: 'Invoices'})
        ret.push({name: 'cinv', label: 'Commercial Invoices'})
        ret.push({name: 'cn',   label: 'Credit Notes'})

        return ret;
    }

    expandCNInvoice = id => {
        let cn = this.state.co.credit_notes;
        let c = _.find(cn, i => {return i.cn_id === id});
        c.expanded = !c.expanded;
        cn[_.findIndex(cn, i => {return i.cn_id === id})] = c;
        this.setState({
            co: {
                ...this.state.co,
                credit_notes: cn
            }
        })
    }

    creditNoteMethods = rowData => {
        let used    = [];
        let ret     = _.map(rowData?.invoice?.payments, (p, idx) => {
            let icon = 'question-circle';
            switch(p.cnp_method){
                case 'Bank Transfer':       icon = 'piggy-bank';            break;
                case 'Card - Stripe':       icon = 'stripe-s';              break;
                case 'Card - Barclaycard':  icon = 'credit-card';           break;
                case 'Invoice':             icon = 'file-invoice-dollar';   break;
            }
            if (!_.find(used, i => i == p.cnp_method)){
                used.push(p.cnp_method);
                return (
                    <Tooltip
                        title={<Typography variant="body2">{p.cnp_method}</Typography>}
                        placement="left"
                    >
                        <IconButton>
                            <AllIcon 
                                icon={icon}
                                button
                                style={{color: rowData.invoice.cni_status == 'Completed' ? colors.green : colors.orange }}
                                noMargin
                            />
                        </IconButton>
                    </Tooltip>
                )
            }
        });
        return (ret);
    }

    //handleShowStickyNote = () => { this.props.deployDialog(<StickyNotes edit={1} orderId={this.state.orderId}/>,null,null,'xs') }

    handlePushBack  = () => {
        API.post(`/sales/orders/${this.state.orderId}/pushBackOrder`, this.state.pushBackFormData)
        .then(result => {
            window.location.href = `/sales/order/view/${result.data.order_id}`;
            this.setState({showPushBackOrder: false});
        });
    }

    showCOPDF = orderId => {
        API.get('/sales/orders/' + orderId + '/pdf')
        .then(result => { 
            if (result.data) this.props.deployDialog(<PDFViewer disableDownload onClose={this.props.closeDialog} pdfData={result.data.pdf} style={{ height:'100%', maxHeight: 700, minHeight: 400, maxWidth: '100%' }} />,'', null, 'md');
        });
    }

    viewFile = file => () => {
        
        this.props.deployDialog(
            <Grid container spacing={1}>
                <Grid item xs={12} style={{textAlign:'center'}}>
                    {getMediaType(file) === 'image' &&
                        <ImageWithError style={{maxWidth: 500, maxHeight: 500}} src={file} />
                    }
                    {getMediaType(file) === 'pdf' &&
                        <PDFViewer pdfUrl={file} />
                    }
                    {(getMediaType(file) !== 'pdf' && getMediaType(file) !== 'image') && 'File Not Supported'}
                </Grid>
                <Grid className='buttonRow' item xs={12}>
                    <Button onClick={()=>{ this.props.closeDialog() }} variant='outlined'>Close</Button>
                </Grid>
            </Grid>, 'View File', 'success', 'md');
    }

    handleDownloadFile = file => () => { downloadS3File(file); }

    showPushBack = () => {
        this.setState({
            showPushBackOrder:true,
            pushBackFormData:   {
                ...initialState().pushBackFormData,
                removePayment: _.filter(this.state.co.payments, {op_txn_status: 'Authorised'}).length > 0 ? 1 : 0,
            }
        })
    }

    lockOrder = orderLocked => {
        this.setState({orderLocked});
    }

    handleDialogUploadFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            dialogUploadFormData: {
                ...this.state.dialogUploadFormData,
                file: file
            }
        });
    }

    handleDialogUploadFileSubmit = () => {
        let formData = new FormData();
        formData.append('file', this.state.dialogUploadFormData.file);
        API.post(`/sales/orders/${this.state.orderId}/payments/${this.state.dialogUploadData.id}/upload`, formData).then(result => {
            this.setState({
                dialogUploadOpen: false
            }, () => {
                this.getOrder();
            });
        });
    }

    handleDialogUploadFileDelete = () => {
        API.post(`/sales/orders/${this.state.orderId}/payments/${this.state.dialogUploadData.id}/delete`).then(result => {
            this.setState({
                dialogUploadOpen: false
            }, () => {
                this.getOrder();
            });
        });
    }

    render() {
        const { currentTab, co, files, isLoading, formErrors, statuses, activeStep, enablePayment, enableDespatch, dialogCourierData, dialogPaymentData, expanded, commercialInvoices, access, orderLocked } = this.state;
        const { classes } = this.props;

        if (isLoading) return (<LoadingCircle />)

        if (this.state.redirect === true) {
            return <Redirect 
                        to={{
                            pathname:`/sales/order/view/${this.state.latestSrId}`,
                            state: {snackbarAmendOpen: true}
                        }}
                    />
        }
        
        const tabItems = _.filter([
                                               {name: 'order',    label: 'Order'},
                                               {name: 'notes',    label: `Notes (${co.notes?.length})`},
                                               {name: 'acl',      label: 'Activity Log'},
                                               {name: 'payments', label: 'Payments'},
            //enableDespatch  && (_.filter(co.despatch, i => !i.desp_reference.includes('/MD/')).length > 0 || co.despatch.length === 0 )  &&   {name: 'picking',  label: 'Picking'},
                                                {name: 'picking',  label: 'Picking'},
            enableDespatch   &&                {name: 'despatch', label: 'Despatch'},
                                               {name: 'img',      label: 'Media'},
                                               {name: 'inv',      label: 'Invoices'},
                                               {name: 'cinv',     label: 'Commercial Invoices'},
                                               {name: 'cn',       label: 'Credit Notes'},
            co.customer_returns?.length > 0 && {name: 'cr',       label: 'Customer Returns'},
            co?.quotation_full_reference?.repair && {name: 'ro',       label: `Repair Order`},
            co.greenaware    &&                {name: 'grn',      label: 'Greenaware'}, 
            co.picking_files &&                {name: 'pf',       label: 'Linked Part Files'},
            co.replacement_return           && {name: 'rr',       label: _.find(co?.replacement_return?.outcomes, {cro_outcome_id: co.order_original_id })?.cro_outcome === 'Repair' ? 'Repair For' : 'Replacement For'},
            co.emails         &&               {name: 'emails',   label: `Emails`},
        ], i => i)

        return (            
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Order
                    </Typography>
                </Grid>
                {isLoading ? (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={12} style={{paddingTop: 0, paddingBottom: 0}}>
                                <StaffActions orderId={co.order_id}  lockCallback={this.lockOrder}/>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <PaddedPaper>
                                    <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                                        {statuses.map(label => (
                                            <Step key={label}>
                                                <StepLabel>{label.replace('Awaiting', '') }</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                                        {co.full_reference}
                                    </Typography>
                                    <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                        Order (v{parseInt(co.order_reference_amendment)})
                                        <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={() => this.downloadCO(co.order_id, false)}
                                        >
                                            <FALightIcon icon='download' />
                                            Download
                                        </Button> 
                                        <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={() => this.showCOPDF(co.order_id)} 
                                        >
                                            <FALightIcon icon={icons.search} />
                                            View
                                        </Button> 
                                    </Typography>
                                    <br className='xs-only' /> 
                                    <TabBar
                                        tabs={_.map(tabItems, i => ({label: i.label, value: i.name}))}
                                        currentTab={currentTab}  
                                        handleTabChange={this.handleTabChange}
                                    />
                                    {
                                        currentTab === 'emails' &&
                                        <TabContainer>
                                            <PaddedPaper>
                                                <ViewEmails
                                                    orderId={co.order_id}
                                                    smallMode
                                                    sendProps={
                                                        {
                                                            to: [co.order_delivery_cust_contact_id],
                                                            customerId: co.order_cust_id,
                                                        }
                                                    }
                                                />
                                            </PaddedPaper>
                                        </TabContainer>
                                    }
                                    {currentTab === 'ro' && <TabContainer><PaddedPaper><RepairOrder cq={co?.quotation_full_reference}/></PaddedPaper></TabContainer>}
                                    {currentTab === 'rr' && <TabContainer><PaddedPaper><ReplacementFor id={co.replacement_return.cr_id} /></PaddedPaper></TabContainer>}
                                    {currentTab === 'cr' && <TabContainer><PaddedPaper><CustomerReturns ids={_.map(co.customer_returns, i => i.cr_id)} history={this.props.history} /></PaddedPaper></TabContainer> }
                                    {currentTab === 'grn' && <TabContainer><PaddedPaper><Greenaware id={co.order_id} /></PaddedPaper></TabContainer> }
                                    {currentTab === 'pf'  && <TabContainer><PaddedPaper><PickingFiles order={co} /></PaddedPaper></TabContainer> }
                                    {currentTab === 'img' &&
                                        <TabContainer>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <PaddedPaper>
                                                        <EngineerMedia orderId={co.order_id} customerId={co.order_cust_id} siteId={co.order_delivery_cust_address_id } slim />
                                                    </PaddedPaper>
                                                </Grid>
                                            </Grid>
                                        </TabContainer>
                                    }
                                    {/* ORDER */}
                                    {currentTab === 'order' &&
                                        <TabContainer>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <PaddedPaper>
                                                        <Order co={co} />
                                                    </PaddedPaper>
                                                </Grid>
                                            </Grid>
                                        </TabContainer>
                                    }
                                    {/* NOTES */}
                                    {currentTab === 'notes' &&
                                        <TabContainer>
                                            <Grid container spacing={3}>
                                                {co.notes && 
                                                    _.orderBy(co?.notes, 'co_note_id', 'desc')?.map(note => (
                                                        <Grid key={note.co_note_id} item xs={12}>
                                                            <NoteBlock 
                                                                prefix          = {'co'}
                                                                deployDialog    = {this.props.deployDialog} 
                                                                note            = {note} 
                                                                editCallback    = {this.softUpdate}
                                                                deleteCallback  = {this.handleDeleteNote}
                                                            />
                                                        </Grid>
                                                    ))
                                                }
                                                {!co.notes.length > 0 && (
                                                    <Grid item xs={12}>
                                                        <Card>   
                                                            <CardHeader                                                       
                                                                title='No notes'
                                                            />
                                                            <CardContent>
                                                                <Typography component="p">
                                                                    There are no notes to show for this order
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </TabContainer>
                                    }
                                    {/* ACTIVITY LOG */}
                                    {currentTab === 'acl' &&
                                        <TabContainer>
                                            <Card>
                                                <CardContent>
                                                    <VerticalTimeline layout="1-column">
                                                        {co.activity && co.activity.map((log, key) => (
                                                            <VerticalTimelineElement key={key}
                                                                className="vertical-timeline-element--work"
                                                                date={moment(log.order_log_datetime_added).format("DD/MM/YYYY HH:mm") + ' - ' + log.order_log_staff}
                                                                iconStyle={{ backgroundColor: log.color, color: 'white' }}
                                                                icon={<FASolidIcon icon={log.icon} style={{color:'white'}} />}
                                                            >
                                                                <h3 className="vertical-timeline-element-title">{log.order_log_type}</h3>
                                                                {log.order_log_text && log.order_log_text.split('\n').map((item, key) => {
                                                                    return <span key={key}><TypographyAllowHtml body={ `${item} <br/>`} /></span>
                                                                })}
                                                            </VerticalTimelineElement>
                                                        ))}
                                                    </VerticalTimeline>
                                                </CardContent>
                                            </Card>
                                        </TabContainer>
                                    }
                                    {/* PAYMENTS */}
                                    {currentTab === 'payments' &&
                                        <TabContainer>
                                            <Grid container spacing={3} xs={12}>
                                                <Grid item xs={12} >
                                                    <PaddedPaper>
                                                        <Typography variant="h5" style={{paddingBottom:'1em'}}>
                                                            Confirmed Payment Files
                                                        </Typography>
                                                        {files?.PaymentConfirmation?.length > 0 ? 
                                                            files?.PaymentConfirmation.map((rev, idx) => (
                                                                <Grid container key={idx} >
                                                                    <Grid item >
                                                                        <Tooltip title={'Delete'}>
                                                                            { idx === (files?.PaymentConfirmation?.length - 1) ? 
                                                                                <IconButton size='small' onClick={()=>this.handleDeleteFileConfirmation(rev.co_file_id)} >
                                                                                    <FALightIcon icon='trash-alt' size="small" noMargin button /> 
                                                                                </IconButton> :
                                                                                <IconButton size='small' disabled='disabled'>
                                                                                    <FALightIcon icon='trash-alt' style={{color:'#FFF'}} size="small" noMargin button /> 
                                                                                </IconButton>
                                                                            }
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item  style={{padding:'3px'}}>
                                                                        <MuiLink component="div" variant="body2" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={() => {downloadS3File(rev.file_url)}}>
                                                                            {rev.co_file_filename || '-'}
                                                                        </MuiLink>
                                                                    </Grid>
                                                                </Grid>
                                                            ))
                                                        : (
                                                            <Grid item xs={12}>
                                                                <Typography variant="body2">
                                                                    0 Payment Confirmation File(s)
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </PaddedPaper>
                                                </Grid>
                                                {co.payments && co.payments.length > 0 &&
                                                    <Grid item xs={12}> 
                                                        <Card>
                                                        <CardHeader title='Order Payments'/>
                                                            <CardContent>
                                                                <DataTable
                                                                    config={{
                                                                        key: 'op_id',
                                                                        withBorder: true,
                                                                        withBorderRadius: true,
                                                                        plainHeader: true
                                                                    }}
                                                                    columns={[
                                                                        {
                                                                            heading: 'Method',
                                                                            field: rowData => {
                                                                                switch (rowData.op_txn_method){
                                                                                    case 'Stripe':  return 'Card - Stripe';
                                                                                    case 'Card':    return 'Card - Barclaycard';
                                                                                    default:        return rowData.op_txn_method;
                                                                                }
                                                                            }
                                                                            ,
                                                                            important: true,
                                                                            sizeToContent: true
                                                                        },
                                                                        {
                                                                            heading: 'Amount',
                                                                            field: rowData => rowData.op_txn_amount,
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                            alignment: 'right',
                                                                            fieldPrefix: '£'
                                                                        },
                                                                        {
                                                                            heading: 'Status',
                                                                            field: rowData => <> <FASolidIcon 
                                                                                                    icon='circle'
                                                                                                    size='small'
                                                                                                    style={{color: (rowData.op_txn_status == 'Authorised' ? colors.green : (rowData.op_txn_status == 'Pending' ? colors.orange : colors.red) ) }}
                                                                                                    /> {rowData.op_txn_status} </>,
                                                                                                    important: true,
                                                                                                    sizeToContent: true
                                                                                                },
                                                                                                {
                                                                                                    heading: 'Created',
                                                                                                    field: rowData => moment(rowData.op_created_at_datetime).format("DD/MM/YYYY"),
                                                                                                    important: true,
                                                                                                    sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'Paid',
                                                                            field: rowData => rowData.op_payment_datetime != '0000-00-00 00:00:00' ? moment(rowData.op_payment_datetime).format("DD/MM/YYYY") : '-',
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            actions: rowData => {
                                                                                return [
                                                                                    {name: 'Download',        icon: icons.download, onClick: this.handleDownloadFile(rowData.file_upload),   disabled: !rowData.op_file },
                                                                                    {name: 'Payment Details', icon: 'search', onClick: () => this.handlePaymentOpen(rowData, 'order'), disabled: (rowData.op_txn_status !='Authorised' && rowData.op_txn_status !='Cancelled - Pushback') && true }
                                                                                ]
                                                                            },
                                                                            alignment: 'right'
                                                                        }
                                                                    ]}
                                                                    rows={co.payments}
                                                                />
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                }
                                                {co.invoices && _.sumBy(co.invoices, i => i.invoice_payments.length) > 0 &&
                                                    <Grid item xs={12} >
                                                        <Card>
                                                            <CardHeader title='Invoice Payments'/>
                                                            <CardContent>
                                                                <DataTable
                                                                    config={{
                                                                        key: 'ip_id',
                                                                        withBorder: true,
                                                                        withBorderRadius: true,
                                                                        plainHeader: true
                                                                    }}
                                                                    columns={[
                                                                        {
                                                                            heading: 'Method',
                                                                            field: rowData => {
                                                                                switch (rowData.ip_txn_method){
                                                                                    case 'Stripe':  return 'Card - Stripe';
                                                                                    case 'Card':    return 'Card - Barclaycard';
                                                                                    default:        return rowData.ip_txn_method;
                                                                                }
                                                                            }
                                                                            ,
                                                                            important: true,
                                                                            sizeToContent: true
                                                                        },
                                                                        {
                                                                            heading: 'Amount',
                                                                            field: rowData => rowData.ip_txn_amount,
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                            alignment: 'right',
                                                                            fieldPrefix: '£'
                                                                        },
                                                                        {
                                                                            heading: 'Status',
                                                                            field: rowData => <> <FASolidIcon 
                                                                                                    icon='circle'
                                                                                                    size='small'
                                                                                                    style={{color: (rowData.ip_txn_status == 'Authorised' ? colors.green : (rowData.ip_txn_status == 'Pending' ? colors.orange : colors.red) ) }}
                                                                                                    /> {rowData.ip_txn_status} </>,
                                                                                                    important: true,
                                                                                                    sizeToContent: true
                                                                                                },
                                                                                                {
                                                                                                    heading: 'Created',
                                                                                                    field: rowData => moment(rowData.ip_created_at_datetime).format("DD/MM/YYYY"),
                                                                                                    important: true,
                                                                                                    sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            heading: 'Paid',
                                                                            field: rowData => rowData.ip_payment_datetime != '0000-00-00 00:00:00' ? moment(rowData.ip_payment_datetime).format("DD/MM/YYYY") : '-',
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                            alignment: 'right'
                                                                        },
                                                                        {
                                                                            actions: rowData => {
                                                                                return [
                                                                                    {name: 'Upload',          icon: icons.upload,   onClick: this.handleUploadOpen(rowData, 'invoice') },
                                                                                    {name: 'Download',        icon: icons.download, onClick: this.handleDownloadFile(rowData.file_upload),   disabled: !rowData.ip_file },
                                                                                    {name: 'Payment Details', icon: icons.search,   onClick: () => this.handlePaymentOpen(rowData, 'invoice'), disabled: rowData.ip_txn_status!='Authorised' && true }
                                                                                ]
                                                                            },
                                                                            alignment: 'right'
                                                                        }
                                                                    ]}
                                                                    rows={_.flatten(_.map(co.invoices, i => i.invoice_payments))}
                                                                />
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                }
                                                
                                                {co.invoice_payment_terms &&
                                                    <Grid item xs={12} >
                                                        <PaddedPaper>
                                                            <Typography variant="h5" style={{paddingBottom:'1em'}}>
                                                                Direct Debit / Spread Payments
                                                            </Typography>
                                                            <Grid container xs={12}>
                                                                <Grid item xs={1}>
                                                                    Terms:
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    {_.replace(co.invoice_payment_terms.sub_payment_term.payment_term_name, 'Account - ', '')}
                                                                </Grid>
                                                                <Grid item xs={1}>Payments:</Grid>
                                                                <Grid item xs={2}>
                                                                    {_.filter(co.invoicePayments, {status: 'complete'}).length}/{co.invoice_payment_terms.oipt_payments}
                                                                </Grid>
                                                                <Grid item xs={1}>Amount:</Grid>
                                                                <Grid item xs={2}>
                                                                    £{parseFloat(co.invoice_payment_terms.oipt_payment_amount).toFixed(2)}
                                                                </Grid>
                                                                <Grid item xs={1}>End Date:</Grid>
                                                                <Grid item xs={2}>
                                                                    {moment(co.invoice_payment_terms.oipt_end_date).format('DD/MM/YYYY')}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} style={{marginTop: '1em'}}>Payments:</Grid>
                                                            <Grid container xs={12} spacing={1} style={{marginTop: '0.5em'}}>
                                                                {_.map(co.invoicePayments, i =>
                                                                    <Grid item>
                                                                        <Chip 
                                                                            style={{
                                                                                padding:            8, 
                                                                                backgroundColor:    ( (   i.status === 'due'        &&  colors.orange ) || 
                                                                                                        ( ( i.status === 'complete' &&  colors.green  ) ||
                                                                                                        ( i.status === 'overdue'    &&  colors.red    )
                                                                                                        )
                                                                                                    ),
                                                                                color:              i.status !== 'TBC' && colors.white
                                                                            }}
                                                                            icon={<AllIcon icon={i.icon} size='small' style={{color: i.status !== 'TBC' && colors.white}}/>}
                                                                            label={`${i.to.format('DD/MM/YYYY')}`} 
                                                                        />
                                                                    </Grid>     
                                                                )}
                                                                
                                                            </Grid>
                                                        </PaddedPaper>
                                                    </Grid>
                                                }
                                            </Grid>
                                            
                                        </TabContainer>
                                    }
                                    {/* PICKING */}
                                    {currentTab === 'picking' &&
                                        <TabContainer>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel shrink={true} className={classes.label}>Expected Picking Date</InputLabel>
                                                        <Input value={moment(co.order_picking_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {co?.order_pick ? (
                                                        <Grid container spacing={3}>
                                                            {_.map(co.order_pick?.picking_details, (pd, idx) => { 
                                                                let od = _.find(co.order_details, {'od_id': pd.opd_od_id});
                                                                
                                                                return (
                                                                    <Grid key={idx} item xs={12}>
                                                                        <Card>
                                                                            <CardContent>
                                                                                <Table>
                                                                                    <TableHead>
                                                                                        <TableRow style={{backgroundColor: '#f2f2f2'}}>
                                                                                            <TableCell colSpan={2}>Part</TableCell>
                                                                                            <TableCell>Status</TableCell>
                                                                                            <TableCell align='right'>Required</TableCell>
                                                                                            <TableCell align='right'>Picked</TableCell>
                                                                                            <TableCell align='right'>To Follow</TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        <TableRow>
                                                                                            <TableCell  style={{height:65, width: 100, verticalAlign: 'top'}}>
                                                                                                {<img src={(od.part && od.part.default_image && od.part.default_image.library_thumbnail_url) ? od.part.default_image.thumbnail_file_path : logo} style={{overflow:'visible', maxHeight: 45, maxWidth: 45}} alt="" />}
                                                                                            </TableCell>
                                                                                            <TableCell style={{whiteSpace:'nowrap', verticalAlign: 'top', width: 500}}>
                                                                                                {pd.order_detail?.od_part_number + ' ' + pd.order_detail?.od_part_description} <br></br>
                                                                                                {od.od_notes && 
                                                                                                    <small style={{color: '#757575', whiteSpace: 'pre-wrap'}}> 
                                                                                                        {od.od_notes} <br/>
                                                                                                    </small>
                                                                                                } 
                                                                                                {pd.order_detail?.od_electrical_connection && 
                                                                                                    <small style={{color: '#757575'}}> 
                                                                                                        {`${pd.order_detail?.od_electrical_connection} Connection`} <br/>
                                                                                                    </small>
                                                                                                } 
                                                                                                {pd.picks.length > 0 && _.find(pd.picks, p => p.pick_serial_numbers !== '[]') && 
                                                                                                    <small style={{color: '#757575'}}> 
                                                                                                        {_.map(pd.picks, p => <>{_.map(JSON.parse(p.pick_serial_numbers), s => <>{s}<br/></>)}</>)}
                                                                                                        <br/>
                                                                                                    </small>
                                                                                                }
                                                                                                {pd.picks.length > 0 && _.find(pd.picks, p => p.pick_warranty_labels !== '[]') && 
                                                                                                    <small style={{color: '#757575'}}> 
                                                                                                        {_.map(pd.picks, p => <>{_.map(JSON.parse(p.pick_warranty_labels), s => <>{s}<br/></>)}</>)}
                                                                                                        <br/>
                                                                                                    </small>
                                                                                                }
                                                                                            </TableCell>
                                                                                            <TableCell style={{verticalAlign: 'top'}}>
                                                                                                <FASolidIcon 
                                                                                                    icon='circle'
                                                                                                    size='small'
                                                                                                    style={{color: (pd.opd_status == 'Complete' ? colors.green : (pd.opd_status == 'Pending' ? colors.orange : colors.red) ) }}
                                                                                                /> {pd.opd_status}
                                                                                            </TableCell>
                                                                                            <TableCell style={{verticalAlign: 'top'}} align='right'>{pd.opd_quantity_required}</TableCell>
                                                                                            <TableCell style={{verticalAlign: 'top'}} align='right'>{pd.opd_quantity_picked}</TableCell>
                                                                                            <TableCell style={{verticalAlign: 'top'}} align='right'>{pd.opd_quantity_to_follow}</TableCell>
                                                                                        </TableRow>
                                                                                        {pd.picks.length > 0 &&
                                                                                            <>
                                                                                                <TableRow>
                                                                                                    <TableCell colSpan={6}>
                                                                                                        <b>Picks</b>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                                {_.map(pd.picks, (pick, idx) => { 
                                                                                                    let desp = _.find(co.despatch, {'desp_id': pick.pick_desp_id});
                                                                                                    return(
                                                                                                        <TableRow key={idx}>
                                                                                                            <TableCell colSpan={2}>
                                                                                                                <Link to="" onClick={(e) => { e.preventDefault(); this.handleDownload(co.order_id, pick.pick_desp_id)}} className='blueLink'>
                                                                                                                    {desp?.full_reference ? desp?.full_reference : '-'}
                                                                                                                </Link>
                                                                                                                <br />
                                                                                                                <small>
                                                                                                                    {pick.staff.staff_first_name} {pick.staff.staff_last_name} - {moment(pick.pick_created_at_datetime,"YYYY-MM-DD HH:mm:s").format("DD-MM-YYYY HH:mm")} 
                                                                                                                </small>
                                                                                                            </TableCell>
                                                                                                            <TableCell>
                                                                                                                <FASolidIcon 
                                                                                                                    icon='circle'
                                                                                                                    size='small'
                                                                                                                    style={{color: (pick.pick_status == 'Picked' ? colors.green : (pick.pick_status == 'Skipped' ? colors.orange : colors.red) ) }}
                                                                                                                />
                                                                                                                {pick.pick_status}
                                                                                                            </TableCell>
                                                                                                            <TableCell align='right'>{pick.pick_quantity_required}</TableCell>
                                                                                                            <TableCell align='right'>{pick.pick_quantity_picked}</TableCell>
                                                                                                            <TableCell align='right'>{pick.pick_quantity_to_follow}</TableCell>
                                                                                                        </TableRow>
                                                                                                    )
                                                                                                })}
                                                                                            </>
                                                                                        }
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                )
                                                            })} 
                                                        </Grid> 
                                                        )
                                                    :
                                                    (co?.order_no_despach_reason ? 
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Card>
                                                                    <CardContent>
                                                                        <Typography variant="h5" gutterBottom>
                                                                            No Despatch
                                                                        </Typography>
                                                                        <Typography variant="body1" paragraph>
                                                                            Reason: {co?.order_no_despach_reason ? co?.order_no_despach_reason : '-'}
                                                                        </Typography>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow style={{backgroundColor: '#f2f2f2'}}>
                                                                                    <TableCell></TableCell>
                                                                                    <TableCell>Part</TableCell>
                                                                                    <TableCell align='right'>Picked</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {_.map(co.order_details, (od, idx) => { 
                                                                                    return (
                                                                                        <TableRow>
                                                                                            <TableCell  style={{height:65, width: 100, verticalAlign: 'top'}}>
                                                                                                {<img src={(od.part && od.part.default_image && od.part.default_image.library_thumbnail_url) ? od.part.default_image.thumbnail_file_path : logo} style={{overflow:'visible', maxHeight: 45, maxWidth: 45}} alt="" />}
                                                                                            </TableCell>
                                                                                            <TableCell style={{whiteSpace:'nowrap', verticalAlign: 'top', width: 500}}>
                                                                                                {od?.od_part_number + ' - ' + od?.od_part_description} <br></br>
                                                                                                {od?.od_electrical_connection && 
                                                                                                    <small style={{color: '#757575'}}> 
                                                                                                        {`${od?.od_electrical_connection} Connection`} <br/>
                                                                                                    </small>
                                                                                                } 
                                                                                                {od?.serial_numbers && od?.serial_numbers.length > 0 && 
                                                                                                    <small style={{color: '#757575'}}> 
                                                                                                        {_.map(od?.serial_numbers, sn => <>{sn?.psnsl_serial_number}<br /></>)}
                                                                                                        <br/>
                                                                                                    </small>
                                                                                                }
                                                                                                {od?.warranty_void_labels && od?.warranty_void_labels.length > 0 && 
                                                                                                    <small style={{color: '#757575'}}> 
                                                                                                        {_.map(od?.warranty_void_labels, sn => <>{sn?.pwvll_code}<br /></>)}
                                                                                                        <br/>
                                                                                                    </small>
                                                                                                }
                                                                                            </TableCell>
                                                                                            <TableCell style={{verticalAlign: 'top'}} align='right'>{od?.od_quantity}</TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                })}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        </Grid>
                                                    :
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Card>
                                                                    <CardContent>
                                                                        <Typography variant="body2" gutterBottom>
                                                                            There are no picking details for this order
                                                                        </Typography>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                        
                                                    }
                                                </Grid> 
                                            </Grid> 
                                        </TabContainer>
                                    }
                                    {/* DESPATCH */}
                                    {currentTab === 'despatch' && (
                                        <TabContainer>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={3}>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel shrink={true} className={classes.label}>Expected Picking Date</InputLabel>
                                                                <Input value={moment(co.order_picking_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel shrink={true} className={classes.label}>Expected Despatch Date</InputLabel>
                                                                <Input value={moment(co.order_despatch_date !== "0000-00-00" && co.order_despatch_date !== null ? co.order_despatch_date : co.despatch[0].desp_despatched_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel shrink={true} className={classes.label}>Expected Delivery Date</InputLabel>
                                                                <Input value={moment(co.order_delivery_date !== "0000-00-00" && co.order_delivery_date !== null ? co.order_delivery_date : co.despatch[0].desp_delivery_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel shrink={true} className={classes.label}>Courier</InputLabel>
                                                                <Input value={co?.despatch?.length > 0 ? _.uniq(_.map(co.despatch, i => i.courier?.cour_name )).join(', ') : co?.courier?.cour_name} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='truck' /></InputAdornment>} />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                            <br />
                                            <Card>
                                                <CardContent>
                                                    <DataTable  
                                                        config={{
                                                            key: 'desp_id',
                                                            plainHeader: true
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: 'Reference',
                                                                field: rowData =>   
                                                                    <Link to="" onClick={(e) => { e.preventDefault(); this.handleDownload(co.order_id, rowData.desp_id)}} className='blueLink'>
                                                                        {rowData.full_reference}
                                                                    </Link>,
                                                                sizeToContent: true,
                                                                main: true
                                                            },
                                                            {
                                                                heading: 'Picked Date',
                                                                field: rowData =>  rowData.desp_picked_date == '0000-00-00' ? '-' : moment(rowData.desp_picked_date).format('DD/MM/YYYY'),
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Despatched Date',
                                                                field: rowData =>  rowData.desp_despatched_date == '0000-00-00' ? '-' : moment(rowData.desp_despatched_date).format('DD/MM/YYYY'),
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Delivery Date',
                                                                field: rowData => moment(rowData.desp_delivery_date).format('DD/MM/YYYY'),
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Despatch Method',
                                                                field: rowData => <>{rowData.courier?.cour_name} <br /> 
                                                                                    {_.filter(this.state.files?.CourierDespatchNote, {'co_file_despatch_id': rowData.desp_id}).length}
                                                                                    <small> Courier Despatch Note(s)</small>
                                                                                </>,
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Status',
                                                                field: rowData => <>
                                                                                    <FASolidIcon 
                                                                                        icon='circle'
                                                                                        size='small'
                                                                                        style={{color: rowData.desp_status == 'Complete' ? colors.green : colors.orange }}
                                                                                    />
                                                                                    {rowData.desp_status} </>,
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                actions: rowData => {
                                                                    return [
                                                                        {name: 'Courier Details', icon: 'file-search', onClick: () => this.handleCourierOpen(rowData)},
                                                                        {name: 'Create Commercial Invoice', icon: 'file-invoice', link: `/sales/order/commercial-invoice/add/${rowData.desp_id}`, disabled: ( rowData.desp_reference.includes('/MD/') || rowData.commercial_invoice_count > 0 ) }
                                                                    ]
                                                                },
                                                                alignment: 'center'
                                                            }
                                                        ]}
                                                        rows={co.despatch}
                                                    />
                                                </CardContent>
                                            </Card>
                                        </TabContainer>
                                    )}
                                    {/* INVOICES */}
                                    {currentTab === 'inv' && (
                                        <TabContainer>
                                            <Grid container spacing={3}>   
                                                {co?.invoices.length == 0 &&
                                                    <Grid item xs={12} style={{padding: '2em 1.5em'}}>
                                                        No invoices have been created for this order
                                                    </Grid>
                                                }
                                                {_.map(co?.invoices, (invoice, idx) => {
                                                    return (
                                                        <Grid item xs={12} key={idx}>
                                                            <Paper>
                                                                <CardHeader
                                                                    title={<Typography variant={'h6'}>{invoice.invoice_number}</Typography>}
                                                                    subheader={
                                                                        <>
                                                                        <Typography variant={'caption'} paragraph>
                                                                            Sage: {invoice.invoice_sage_invoice_number}
                                                                        </Typography>
                                                                        <Typography variant={'body2'} gutterBottom>
                                                                            {invoice.past_revitions.map( i => 
                                                                                <Link to="" onClick={(e) => { e.preventDefault(); this.handleDownloadInvoice(i.invoice_id)}} className='blueLink'>
                                                                                    {`${i.invoice_reference}-${i.invoice_reference_unique_number}`} <br/>
                                                                                </Link>
                                                                            )}
                                                                            
                                                                        </Typography>
                                                                        <Typography variant={'body1'} gutterBottom>
                                                                            {invoice.invoice_status == 'Completed' ?
                                                                                <Chip size="small" label="Paid" style={{backgroundColor: colors.green, color: '#fff', marginRight:'20px'}} />
                                                                            :
                                                                                (invoice.invoice_status == 'Approval' ?
                                                                                    <Chip size="small" label="Awaiting Approval" style={{backgroundColor: colors.orange, color: '#fff', marginRight:'20px'}} />
                                                                                :
                                                                                    <Chip size="small" label="Pending" style={{backgroundColor: colors.orange, color: '#fff', marginRight:'20px'}} />
                                                                                )
                                                                            }
                                                                            <span style={{fontSize:'12px'}}>Paid: </span>£{invoice.sum_of_payments || '0.00'}
                                                                            <span style={{fontSize:'30px', color: 'rgba(0, 0, 0, 0.2)'}}> / </span> 
                                                                            <span style={{fontSize:'12px'}}>Due: </span>£{invoice.invoice_total_incl_vat}
                                                                            <span style={{fontSize:'30px', color: 'rgba(0, 0, 0, 0.2)'}}> / </span> 
                                                                            <span style={{fontSize:'12px'}}>Outstanding: </span>£{invoice.amount_outstanding}
                                                                        </Typography>
                                                                        </>
                                                                    }
                                                                    action={
                                                                        <>
                                                                        {invoice.invoice_status != 'Approval' &&
                                                                            <IconButton
                                                                                className={clsx(classes.expand, {
                                                                                    [classes.expandOpen]: expanded[idx],
                                                                                })}
                                                                                onClick={() => this.handleExpandInvoices(idx, invoice)}
                                                                            >
                                                                                <FALightIcon icon="chevron-down" button noMargin/>
                                                                            </IconButton>
                                                                        }
                                                                        </>
                                                                    }
                                                                    disableTypography
                                                                />  
                                                                <Collapse in={expanded[idx]} timeout="auto" unmountOnExit>
                                                                    <CardContent style={{paddingTop: 0}}>
                                                                        {((invoice?.invoice_payments.length + invoice?.invoice_payments.length) > 0 && (      
                                                                            <React.Fragment>                                    
                                                                                <Table size="small">
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell>Status</TableCell>
                                                                                            <TableCell>Order Payment</TableCell>
                                                                                            <TableCell>Date Paid</TableCell>
                                                                                            <TableCell>Method</TableCell>
                                                                                            <TableCell align="right">Amount</TableCell>
                                                                                            <TableCell></TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {_.map(invoice?.invoice_payments, (payment, idx) => {
                                                                                            return (
                                                                                                <TableRow key={idx} style={{textDecoration: payment.ip_txn_status == 'Expired' && 'line-through'}}>
                                                                                                    <TableCell>{payment.ip_txn_status}</TableCell>
                                                                                                    <TableCell>{payment.ip_order_payment_id == 0 ? 'No' : 'Yes'}</TableCell>
                                                                                                    <TableCell>{moment(payment.ip_payment_datetime).format("DD/MM/YYYY")}</TableCell>
                                                                                                    <TableCell>
                                                                                                        {payment.ip_txn_method}{ payment.ip_txn_method == 'Credit Note' && `(${ payment.ip_credit_note_reference})`}
                                                                                                    </TableCell>
                                                                                                    <TableCell align="right">£{payment.ip_txn_amount}</TableCell>
                                                                                                    <TableCell align="right">
                                                                                                        <AllIcon noMargin icon={icons.download} onClick={this.handleDownloadFile(payment.file_upload)} disabled={!payment.file_upload}/>
                                                                                                        <IconButton onClick={() => this.handlePaymentOpen(payment, 'invoice')} disabled={payment.ip_txn_status != 'Authorised' && true}>
                                                                                                            <FALightIcon icon="search" button noMargin/>
                                                                                                        </IconButton>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            )
                                                                                        })}
                                                                                    </TableBody>
                                                                                </Table>     
                                                                            </React.Fragment>
                                                                        )) || (
                                                                            <Typography variant="caption">
                                                                                <IconButton disabled>
                                                                                    <FALightIcon icon="exclamation-circle" />
                                                                                </IconButton>
                                                                                This invoice has 0 payments
                                                                            </Typography>
                                                                        )}
                                                                    </CardContent>   
                                                                </Collapse>                       
                                                            </Paper>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </TabContainer>
                                    )}
                                    {/* COMMERCIAL INVOICES */}
                                    {currentTab === 'cinv' && (
                                        <TabContainer>
                                            <Grid xs={12}>
                                                {Object.keys(commercialInvoices).map((invoiceNumber, idx) => {
                                                    return (
                                                        <ExpansionPanel>
                                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                <Typography variant='body2' gutterBottom>
                                                                    {invoiceNumber} 
                                                                    <br/>
                                                                    <Typography variant='caption'>
                                                                        Sage: {commercialInvoices[invoiceNumber][0].commercial_sage_invoice_number}
                                                                    </Typography> 
                                                                </Typography>
                                                            </ExpansionPanelSummary>
                                                            <ExpansionPanelDetails>
                                                                <Table>
                                                                    <TableBody>
                                                                        {_.map(commercialInvoices[invoiceNumber], (prev, idx) => {
                                                                            return (
                                                                                <TableRow key={idx}>
                                                                                <TableCell>
                                                                                    <Link to="" onClick={(e) => { e.preventDefault(); this.handleDownloadCommercialInvoice(prev.commercial_id)}} className='blueLink'>
                                                                                            {prev.full_reference}
                                                                                        </Link>
                                                                                    </TableCell>
                                                                                    <TableCell align="right">
                                                                                        {prev.commercial_latest == 1 &&
                                                                                            <Link to={{ pathname: `/sales/order/commercial-invoice/amend/${prev.commercial_id}`}}>
                                                                                                <IconButton >
                                                                                                    <FALightIcon icon="pencil" button noMargin/>
                                                                                                </IconButton>
                                                                                            </Link>
                                                                                        }
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </ExpansionPanelDetails>
                                                        </ExpansionPanel>
                                                    )
                                                })}
                                            </Grid>
                                        </TabContainer>
                                    )}
                                    {/* Credit Notes */}
                                    {currentTab === 'cn' &&
                                        <TabContainer>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <PaddedPaper>
                                                        {!!co.credit_notes.length ? 
                                                            <DataTable
                                                            config={{
                                                                key: 'cn_id',
                                                                pagination: false,
                                                                alternatingRowColours: true,
                                                                responsiveimportant: true,
                                                                isLoading: isLoading,
                                                            }}
                                                            columns={[
                                                                {
                                                                    heading: 'Credit Date',
                                                                    field: rowData => moment(rowData.cn_date).format("DD/MM/YYYY"),
                                                                    important: true,
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    heading: 'Reference',
                                                                    field: rowData => <span style={{color:colors.red}}>{`${rowData.cn_reference}-0${rowData.cn_reference_amendment}`}</span>,
                                                                    important: true,
                                                                },
                                                                {
                                                                    heading: 'Discount',
                                                                    field: rowData => rowData.cn_type === 'With Discount' ? 
                                                                        <AllIcon icon='check' style={{color:colors.green}} /> : 
                                                                        <AllIcon icon='times' style={{color:colors.red}}/>,
                                                                    important: true,
                                                                    fieldFormat: 'boolean',
                                                                    tooltip: rowData => rowData.cn_type,
                                                                    sizeToContent: true,
                                                                    alignment: 'center'
                                                                },
                                                                {
                                                                    heading: 'Customer',
                                                                    field: rowData => <>
                                                                                        {rowData.cn_cust_name}
                                                                                        {' '}
                                                                                        <Grid container spacing={1}>
                                                                                            <Grid item>
                                                                                                <Typography component="div" variant="body2">
                                                                                                    {rowData.cn_delivery_cust_address_name}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs>
                                                                                                <Tooltip
                                                                                                    title={
                                                                                                        <div>
                                                                                                            <Typography variant="body2">
                                                                                                                <b>Credit Address:</b><br />
                                                                                                                {rowData.cn_delivery_cust_address.split('\n').map((item, key) => {
                                                                                                                    return  <span key={key}>
                                                                                                                                {item}{rowData.cn_delivery_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                                                                                            </span>
                                                                                                                })}
                                                                                                            </Typography>
                                                                                                        </div>
                                
                                                                                                    }
                                                                                                    placement="right"
                                                                                                >
                                                                                                    <Link>
                                                                                                        <AllIcon
                                                                                                            heavy 
                                                                                                            icon='info-circle'
                                                                                                            button 
                                                                                                            size='small'
                                                                                                            style={{
                                                                                                                color: 'rgba(0, 0, 0, 0.38)'
                                                                                                            }}
                                                                                                        />
                                                                                                    </Link>
                                                                                                </Tooltip>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </>,
                                                                    important: true
                                                                },
                                                                {
                                                                    heading: 'Total',
                                                                    field: rowData => ( 
                                                                        <div style={{color:colors.red}}>
                                                                            -£{rowData.cn_total_incl_vat}
                                                                            {' '}
                                                                            <Tooltip 
                                                                                title={
                                                                                    <div style={{display: 'flex', width: 200}}>
                                                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                                                            <Typography variant="body2">Sub Total</Typography>
                                                                                            <Typography variant="body2">VAT ({parseInt(rowData.vat.vat_value_percentage)}%)</Typography>
                                                                                            <Typography variant="body2">Total</Typography>
                                                                                        </div>
                                                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                                                            <Typography variant="body2">£{rowData.cn_sub_total}</Typography>
                                                                                            <Typography variant="body2">£{rowData.cn_vat}</Typography>
                                                                                            <Typography variant="body2">-£{rowData.cn_total_incl_vat}</Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                } 
                                                                                placement="left"
                                                                            >
                                                                                <Link>
                                                                                    <AllIcon
                                                                                        heavy 
                                                                                        icon='info-circle'
                                                                                        button 
                                                                                        size='small'
                                                                                        style={{
                                                                                            color: 'rgba(0, 0, 0, 0.38)'
                                                                                        }}
                                                                                    />
                                                                                </Link>
                                                                            </Tooltip>
                                                                        </div>
                                                                    ),
                                                                    important: true,
                                                                    alignment: 'right',
                                                                },
                                                                {
                                                                    heading: 'Refund Method',
                                                                    field: rowData =>   <>
                                                                                            {this.creditNoteMethods(rowData)}
                                                                                        </>,
                                                                    important: true,
                                                                    alignment: 'center',
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    actions: rowData => {
                                                                        return [
                                                                            {name: 'Download', icon: icons.download, onClick: this.handleDownloadFile(rowData.file_upload),   disabled: !rowData.file_upload },
                                                                            {name: 'View',     icon: icons.search,   link: {pathname: `/returns/creditnote/${rowData.cn_id}`}}
                                                                        ]
                                                                    }
                                                                }
                                                            ]}
                                                                rows    ={co.credit_notes}
                                                            /> 
                                                            : 
                                                            <Typography component="p">
                                                                There are no credit notes to show for this order
                                                            </Typography>
                                                        }
                                                    </PaddedPaper>
                                                    {!!co.credit_notes.length && 
                                                        <PaddedPaper style={{marginTop:'1.5em'}}>
                                                            {_.map(_.filter(co.credit_notes, i => {return i.invoice}), creditNote => 
                                                                <Grid container spacing={3}>   
                                                                    <Grid item xs={12}>
                                                                        <Paper>
                                                                            <CardHeader 
                                                                                title={<Typography variant={'h6'}>{creditNote.invoice.cni_invoice_number}</Typography>}
                                                                                subheader={
                                                                                    <>
                                                                                        <Typography variant={'caption'} paragraph>Sage: {creditNote.invoice.cni_sage_invoice_number}</Typography>
                                                                                        <Typography variant={'body2'} gutterBottom>
                                                                                            {creditNote.invoice.past_revisions.map( i => 
                                                                                                creditNote.invoice.cni_status  == 'Approval' ? 
                                                                                                    <>{`${i.cni_reference}-0${i.cni_reference_unique_number}`} <br/></>
                                                                                                :
                                                                                                    <Link to="" onClick={(e) => { e.preventDefault(); this.handleDownloadCreditNoteInvoice(i.cni_id)}} className='blueLink'>
                                                                                                        {`${i.cni_reference}-0${i.cni_reference_unique_number}`} <br/>
                                                                                                    </Link>
                                                                                            )}
                                                                                        </Typography>
                                                                                        <Typography variant={'body1'} gutterBottom>
                                                                                            {creditNote.invoice.cni_status == 'Completed' ? <Chip size="small" label="Refunded" style={{backgroundColor: colors.green, color: '#fff', marginRight:'20px'}} /> : (creditNote.invoice.cni_status  == 'Approval' ? <Chip size="small" label="Awaiting Approval" style={{backgroundColor: colors.orange, color: '#fff', marginRight:'20px'}} /> :<Chip size="small" label="Pending" style={{backgroundColor: colors.orange, color: '#fff', marginRight:'20px'}} />)}
                                                                                            <span style={{fontSize:'12px'}}>Refunded: </span><span  style={{color: colors.red}}>£{creditNote.invoice.sum_of_payments || '0.00'}</span>
                                                                                            <span style={{fontSize:'30px', color: 'rgba(0, 0, 0, 0.2)'}}> / </span> 
                                                                                            <span style={{fontSize:'12px'}}>Due: </span><span  style={{color: colors.red}}>-£{creditNote.invoice.cni_total_incl_vat}</span>
                                                                                            <span style={{fontSize:'30px', color: 'rgba(0, 0, 0, 0.2)'}}> / </span> 
                                                                                            <span style={{fontSize:'12px'}}>Outstanding: </span><span  style={{color: colors.red}}>{parseFloat(creditNote.invoice.cni_total_incl_vat) !== parseFloat(creditNote.invoice.sum_of_payments) ? `-£${(parseFloat(creditNote.invoice.cni_total_incl_vat) - parseFloat(creditNote.invoice.sum_of_payments ?? 0) )}` : '£0'}</span>
                                                                                        </Typography>
                                                                                    </>
                                                                                }
                                                                                action={
                                                                                    <>
                                                                                        {creditNote.invoice.cni_status != 'Approval' &&
                                                                                            <IconButton onClick={()=>{this.expandCNInvoice(creditNote.cn_id)}}><AllIcon icon={creditNote.expanded ? "chevron-up" : "chevron-down"} button noMargin/></IconButton>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                                disableTypography
                                                                            />
                                                                            <Collapse in={creditNote.expanded} timeout="auto" unmountOnExit>
                                                                                <CardContent style={{paddingTop: 0}}>
                                                                                    {!!creditNote.invoice.payments.length ? 
                                                                                        <>                                    
                                                                                            <Table size="small">
                                                                                                <TableHead>
                                                                                                    <TableRow>
                                                                                                        <TableCell>Date Paid</TableCell>
                                                                                                        <TableCell>Method</TableCell>
                                                                                                        <TableCell align="right">Amount</TableCell>
                                                                                                        <TableCell></TableCell>
                                                                                                    </TableRow>
                                                                                                </TableHead>
                                                                                                <TableBody>
                                                                                                    {_.map(creditNote.invoice.payments, (payment, idx) => {
                                                                                                        return (
                                                                                                            <TableRow key={idx}>
                                                                                                                <TableCell>{moment(payment.cnp_refund_date).format("DD/MM/YYYY")}</TableCell>
                                                                                                                <TableCell>
                                                                                                                    {payment.cnp_method} {payment.cnp_method == 'Invoice' &&  <>{`(${payment.cnp_invoice_number})`}</>}
                                                                                                                </TableCell>
                                                                                                                <TableCell align="right">£{payment.cnp_amount}</TableCell>
                                                                                                                <TableCell align="right">
                                                                                                                    <IconButton onClick={() => { this.props.deployDialog(<PaymentDialog rowData={payment} closeDialog={this.props.closeDialog}  />, 'Refund Details', null, 'xs')} }>
                                                                                                                        <AllIcon icon="search" button noMargin/>
                                                                                                                    </IconButton>
                                                                                                                </TableCell>
                                                                                                            </TableRow>
                                                                                                        )
                                                                                                    })}
                                                                                                </TableBody>
                                                                                            </Table>     
                                                                                        </>
                                                                                        :<Typography variant="caption">
                                                                                            <IconButton disabled>
                                                                                                <AllIcon icon="exclamation-circle" />
                                                                                            </IconButton>
                                                                                            This invoice has 0 payments
                                                                                        </Typography>
                                                                                    }
                                                                                </CardContent>   
                                                                            </Collapse>   
                                                                        </Paper>
                                                                    </Grid>
                                                                </Grid> 
                                                            )}
                                                        </PaddedPaper>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </TabContainer>
                                    }
                                    <Divider style={{marginBottom:20}} />
                                    <div className={`buttonRow ${classes.btnRow}`}>
                                        <Grid container justify='start' spacing={1}>
                                            {orderLocked ?
                                                <Grid item xs={12} md={12}>
                                                   {orderLocked}
                                                </Grid> :
                                                <>
                                                    <Grid item xs={12} md={2}>
                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleEmailOpen} ><FALightIcon icon='envelope' buttonPrimary /> Email</Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleNotesOpen}><FALightIcon icon='plus' buttonPrimary /> Add Notes</Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleAddFiles}><FALightIcon icon='plus' buttonPrimary /> Add Files</Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleAddEngineerMedia}><FALightIcon icon='plus' buttonPrimary /> Add Engineer Media</Button>
                                                    </Grid>
                                                    {access.repeatOrder &&
                                                        <Grid item xs={12} md={2}>
                                                            <Button variant="contained" className={classes.btnRowBtn} color='primary' onClick={()=>this.props.history.push(`/sales/order/add/repeat/${co.order_id}`)}><AllIcon icon={icons.recurring} white/> Repeat Order</Button>
                                                        </Grid>
                                                    }
                                                    {(co.order_status == 'Completed' || co.order_status == 'Invoice') && access.creditNote && 
                                                        <Grid item xs={12} md={3}>
                                                            <Link to={`/sales/order/add/creditnote/${co.order_id}`} className={classes.link}>
                                                                <Button variant="contained" className={classes.btnRowBtn} color="primary" ><FALightIcon icon='wallet' buttonPrimary /> Add Credit Note</Button>
                                                            </Link>
                                                        </Grid>
                                                    }
                                                    {(co.order_status == 'Completed' || co.order_status == 'Invoice') && access.customerReturn  &&
                                                        <Grid item xs={12} md={3}>
                                                            <Link to={`/returns/add/${co.order_id}`} className={classes.link}>
                                                                <Button variant="contained" className={classes.btnRowBtn} color="primary" ><FALightIcon icon='wallet' buttonPrimary /> Add Customer Return</Button>
                                                            </Link>
                                                        </Grid>
                                                    }
                                                    {co.order_status !== 'Picking' && co.order_status !== 'Awaiting Despatch' && co.order_status !== 'Invoice' && 
                                                    co.order_status !== 'Completed' && co.order_status !== 'Declined' && co.order_status !== 'Cancelled' && co.order_status !== 'Payment Confirmed' &&
                                                        (co?.quotation_full_reference?.repair?.rog_status !== 'Awaiting Repair' || (co?.quotation_full_reference?.repair?.rog_status === 'Completed' && parseInt(co.order_parent_id) > 0) ) && 
                                                        (
                                                            <React.Fragment>
                                                                {co?.quotation_full_reference?.repair?.rog_status !== 'Awaiting Repair' &&
                                                                    <Grid item xs={12} md={2}>
                                                                        {_.filter(co.order_details, i => i.part.part_locked).length > 0 ?
                                                                            <Button variant="contained" color="primary"  className={classes.btnRowBtn} disabled><FALightIcon icon={icons.lock} buttonPrimary /> Amend Order</Button>:
                                                                            <Link to={`/sales/order/amend/${co.order_id}`} className={classes.link}>
                                                                                <Button variant="contained" color="primary" className={classes.btnRowBtn}><FALightIcon icon='pen' buttonPrimary /> Amend Order</Button>
                                                                            </Link> 

                                                                        }   
                                                                    </Grid>
                                                                }
                                                                <Grid item xs={12} md={2}>
                                                                    {_.filter(co.order_details, i => i.part.part_locked).length > 0 ?
                                                                        <Button variant="contained" color="primary"  className={classes.btnRowBtn} disabled><FALightIcon icon={icons.lock} buttonPrimary /> Cancel {
                                                                            co?.quotation_full_reference?.repair?.rog_status === 'Awaiting Repair' ? 'Repair' : 'Order'
                                                                        }</Button>:
                                                                        <Button variant="contained" className={classes.btnRowBtn} color="secondary" onClick={this.handleCloseOpen}><FALightIcon icon='ban' buttonPrimary /> Cancel {
                                                                            co?.quotation_full_reference?.repair?.rog_status === 'Awaiting Repair' ? 'Repair' : 'Order'
                                                                        }</Button>
                                                                    }                                      
                                                                </Grid>            
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    {(  ( 
                                                            ( co.order_status == 'Picking' && _.sumBy(co.order_pick.picking_details, i=>parseFloat(i.opd_quantity_picked) ) == 0 ) ||
                                                            ( co.order_status === 'Payment Confirmed' || co.order_status === 'Awaiting Payment')
                                                        ) && access.pushBack && 
                                                        _.filter(co.order_details, i => i.part.part_locked).length == 0
                                                    ) &&
                                                        <Grid item xs={12} md={3}>
                                                            <Button variant="contained" className={classes.btnRowBtn} color="secondary" onClick={this.showPushBack}><FALightIcon icon='clock-rotate-left' buttonPrimary /> Push Back Order</Button>
                                                        </Grid>
                                                    }
                                                </>
                                            }
                                            <Grid item xs={12} md={12} className='buttonRow'>
                                                <BackButton props={this.props} style={{width: '15%'}}/>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12}>
                                            <PaddedPaper>
                                                {co.order_status === 'Awaiting Approval' && 
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom style={{color:colors.orange}} className={classes.statusHeading}>
                                                            <FALightIcon icon='file-user' size='large' style={{color:colors.orange}}/>{co.order_status}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            This order was created on {moment(co.order_created_datetime,"YYYY-MM-DD").format("Do MMMM YYYY")} by {co.order_staff_name}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                {co.order_status === 'Confirmed' &&
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom style={{color:colors.orange}} className={classes.statusHeading}>
                                                            <FALightIcon icon='user-check' size='large' style={{color:colors.orange}}/>{co.order_status}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            This order was created on {moment(co.order_created_datetime,"YYYY-MM-DD").format("Do MMMM YYYY")} by {co.order_staff_name}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                {co.order_status === 'Awaiting Payment' &&
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom style={{color:colors.orange}} className={classes.statusHeading}>
                                                            <FALightIcon icon='credit-card' size='large' style={{color:colors.orange}}/>{co.order_status}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            This order was created on {moment(co.order_created_datetime,"YYYY-MM-DD").format("Do MMMM YYYY")} by {co.order_staff_name}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                {co.order_status === 'Payment Confirmed' &&
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom style={{color:colors.orange}} className={classes.statusHeading}>
                                                            <FALightIcon icon='receipt' size='large' style={{color:colors.orange}}/>{co.order_status}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            This order was created on {moment(co.order_created_datetime,"YYYY-MM-DD").format("Do MMMM YYYY")} by {co.order_staff_name}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                {co.order_status === 'Picking' &&
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom style={{color:colors.orange}} className={classes.statusHeading}>
                                                            <FALightIcon icon='people-carry' size='large' style={{color:colors.orange}}/>{co.order_status}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            This order was created on {moment(co.order_created_datetime,"YYYY-MM-DD").format("Do MMMM YYYY")} by {co.order_staff_name}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                {co.order_status === 'Awaiting Despatch' &&
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom style={{color:colors.orange}} className={classes.statusHeading}>
                                                            <FALightIcon icon='truck' size='large' style={{color:colors.orange}}/>{co.order_status}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            This order was created on {moment(co.order_created_datetime,"YYYY-MM-DD").format("Do MMMM YYYY")} by {co.order_staff_name}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                {co.order_status === 'Invoice' &&
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom style={{color:colors.orange}} className={classes.statusHeading}>
                                                            <FALightIcon icon='file-invoice-dollar' size='large' style={{color:colors.orange}}/>{co.order_status}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            This order was created on {moment(co.order_created_datetime,"YYYY-MM-DD").format("Do MMMM YYYY")} by {co.order_staff_name}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                {co.order_status === 'Completed' &&
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom style={{color:colors.green}} className={classes.statusHeading}>
                                                            <FALightIcon icon='check-circle' size='large' style={{color:colors.green}}/>{co.order_status}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            This order was created on {moment(co.order_created_datetime,"YYYY-MM-DD").format("Do MMMM YYYY")} by {co.order_staff_name}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                {co.order_status === 'Declined' &&
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom style={{color:colors.red}} className={classes.statusHeading}>
                                                            <FALightIcon icon='user-times' size="large" style={{color:colors.red}}/>{co.order_status}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            This order was declined on {moment(co.order_cancelled_datetime, "YYYY-MM-DD").format("Do MMMM YYYY")} by {co.order_cancelled_by_staff_name}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                {co.order_status === 'Cancelled' &&
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom style={{color:colors.red}} className={classes.statusHeading}>
                                                            <FALightIcon icon='ban' style={{color:colors.red}}/>{co.order_status}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            This order was cancelled on {moment(co.order_cancelled_datetime, "YYYY-MM-DD").format("Do MMMM YYYY")} by {co.order_cancelled_by_staff_name}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            </PaddedPaper>
                                        </Grid>
                                        {co.order_quote_id > 0 && co.quotation_full_reference && 
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Typography variant="h5">
                                                        Quotation Reference
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <MuiLink href={`/sales/quotation/view/${co.order_quote_id}`}>{co.quotation_full_reference.full_reference}</MuiLink>
                                                    </Typography>
                                                </PaddedPaper>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            {(access.createStickyNoteRes || (access.viewStickyNoteRes && co.sticky_notes.length > 0) ) &&
                                                <PaddedPaper style={{backgroundColor: colors.stickyNotes}}>
                                                    <Typography variant="h5">
                                                        Sticky Notes
                                                    </Typography>
                                                    <StickyNotes edit={1} orderId={this.state.orderId}/>
                                                </PaddedPaper>
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PaddedPaper>
                                                <Typography variant="h5">
                                                    Previous Order
                                                </Typography>
                                                {co.revisions && co.revisions.length > 0 ?
                                                    co.revisions.map((rev, idx) => (
                                                        <React.Fragment key={idx}>
                                                            <Link to="" onClick={(e) => { e.preventDefault(); this.downloadCO(rev.order_id)}} className='blueLink'>
                                                                {rev.full_reference || '-'}
                                                            </Link>
                                                            <br></br>
                                                        </React.Fragment>
                                                    ))
                                                :
                                                    <Typography variant="body1" gutterBottom>
                                                        0 previous orders
                                                    </Typography>
                                                }
                                            </PaddedPaper>
                                        </Grid>
                                        {!!co.order_repeated_id &&
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Typography variant="h5">
                                                        Order Repeated From
                                                    </Typography>
                                                    <Typography variant="body1" gutterBottom  onClick={()=>{ window.location.href = `/sales/order/view/${co.original_order.order_id}`; }} className='blueLink'>    
                                                        {co.original_order.order_reference}-{co.original_order.order_reference_amendment}
                                                    </Typography>
                                                </PaddedPaper>
                                            </Grid>
                                        }
                                        {co.repeated_orders.length > 0 &&
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Typography variant="h5">
                                                        Order Repeated In
                                                    </Typography>
                                                    {co.repeated_orders.map((rev, idx) => (
                                                        <Typography variant="body1" gutterBottom  onClick={()=>{ window.location.href = `/sales/order/view/${rev.order_id}`; }} className='blueLink'>    
                                                            {rev.order_reference}-{rev.order_reference_amendment}
                                                        </Typography>
                                                    ))}
                                                </PaddedPaper>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <PaddedPaper>
                                                <Typography variant="h5">
                                                    Purchase Orders
                                                </Typography>
                                                {files?.PurchaseOrder?.length > 0 ? 
                                                    files?.PurchaseOrder.map((rev, idx) => (
                                                        <Grid container key={idx} >
                                                            <Grid item xs={1}>
                                                                { idx === (files?.PurchaseOrder?.length - 1) ? 
                                                                    <Tooltip title={'Delete'}>
                                                                        <IconButton size='small' onClick={()=>this.handleDeleteFileConfirmation(rev.co_file_id)}>
                                                                            <FALightIcon icon='trash-alt' size="small" noMargin button /> 
                                                                        </IconButton> 
                                                                    </Tooltip>
                                                                    : <></> 
                                                                }
                                                            </Grid>
                                                            <Grid item xs={11} style={{padding:'2px'}}>
                                                                <MuiLink component="div" variant="body2" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={() => {downloadS3File(rev.file_url)}}>
                                                                    {rev.co_file_filename || '-'}
                                                                </MuiLink>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                : (
                                                    <Grid item xs={12}>
                                                        <Typography variant="body2">
                                                            0 purchase order(s)
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PaddedPaper>
                                                <Typography variant="h5">
                                                    Engineer Job Sheet's
                                                </Typography>
                                                {(files?.EngineerJobSheet?.length > 0 || co?.job_sheets?.length > 0) ? 
                                                    <>
                                                        {!!co?.job_sheets?.length > 0 && co?.job_sheets?.map((js, idx) => (
                                                            <Grid container key={idx} style={{alignItems: 'center'}}>
                                                                <Grid item xs={12} style={{padding:'2px'}}>
                                                                    <MuiLink component="div" variant="body2" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={() => {
                                                                        API.get('/engineers/jobSheets/' + js.js_id)
                                                                            .then(result => {
                                                                                if(result.data) {
                                                                                    pdfFromBase64(result.data.pdf, result.data.pdfName);
                                                                                }
                                                                            });
                                                                    }}>
                                                                        {js.js_ref || '-'}
                                                                    </MuiLink>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                        {!!files?.EngineerJobSheet?.length > 0 && files?.EngineerJobSheet.map((rev, idx) => (
                                                            <Grid container key={idx} style={{alignItems: 'center'}}>
                                                                <Grid item xs={1}>
                                                                    { idx === (files?.EngineerJobSheet?.length - 1) ? 
                                                                        <Tooltip title={'Delete'}>
                                                                            <IconButton size='small' onClick={()=>this.handleDeleteFileConfirmation(rev.co_file_id)}>
                                                                                <FALightIcon icon='trash-alt' size="small" noMargin button /> 
                                                                            </IconButton> 
                                                                        </Tooltip>    
                                                                        : <></> 
                                                                    }
                                                                </Grid>
        
                                                                <Grid item xs={11} style={{padding:'2px'}}>
                                                                    {rev.co_file_sub_type} 
                                                                    <MuiLink component="div" variant="body2" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={() => {downloadS3File(rev.file_url)}}>
                                                                        {rev.co_file_filename || '-'}
                                                                    </MuiLink>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </>
                                                : (
                                                    <Grid item xs={12}>
                                                        <Typography variant="body2">
                                                            0 job sheet(s)
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </PaddedPaper>
                                        </Grid>
                                        {!!files['Call Recording'] && files['Call Recording'].length > 0 &&
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Typography variant="h5">
                                                        Call Recordings
                                                    </Typography>
                                                    {files['Call Recording'].map((rev, idx) => (
                                                        <Grid container key={idx} style={{alignItems: 'center'}}>
                                                            <Grid item xs={1}>
                                                                { idx === (files['Call Recording']?.length - 1) ? 
                                                                    <Tooltip title={'Delete'}>
                                                                        <IconButton size='small' onClick={()=>this.handleDeleteFileConfirmation(rev.co_file_id)}>
                                                                            <FALightIcon icon='trash-alt' size="small" noMargin button /> 
                                                                        </IconButton> 
                                                                    </Tooltip>    
                                                                    : <></> 
                                                                }
                                                            </Grid>

                                                            <Grid item xs={11} style={{padding:'2px'}}>
                                                                <MuiLink component="div" variant="body2" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={() => {downloadS3File(rev.file_url)}}>
                                                                    {rev.co_file_filename || '-'}
                                                                </MuiLink>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </PaddedPaper>
                                            </Grid>
                                        }
                                    </Grid>
                                </>
                            </Grid>
                       </Grid>
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen}
                            onClose={this.handleSnackbarClose}
                            message={this.state.succesMsg}
                        />
                        {/* CANCEL ORDER */}
                        <Dialog 
                            open={this.state.dialogCloseOpen} 
                            onClose={this.handleCloseClose} 
                            fullWidth={true} 
                            scroll="body"
                            disableBackdropClick
                            disableEscapeKeyDown
                        >
                            <DialogTitle>Cancel This Order?</DialogTitle>
                            <DialogContent>
                                {!!this.state.dialogCloseOpen &&
                                    <Box style={{marginBottom: '1em'}}>
                                        <StaffActions orderId={co.order_id} type='Cancelling'/>
                                    </Box>
                                }                   
                                <DialogContentText>
                                    Are you sure you want to cancel this order?<br/>
                                    {co?.quotation_full_reference?.repair?.rog_status === 'Awaiting Repair' &&
                                        ' * Please note that this will return the repair to awaiting repair status'
                                    }
                                </DialogContentText>
                                <Textarea
                                    id="notesText"
                                    name="notesText"
                                    label="Notes *"
                                    value={this.state.formData.notesText}
                                    rows={5}
                                    error={formErrors && formErrors['notesText']}
                                    onChange={this.handleChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseClose} variant="outlined" color="default">Close</Button>
                                <Button onClick={this.handleCloseSuccess} color="secondary" autoFocus variant="contained">Cancel Order</Button>
                            </DialogActions>
                        </Dialog>
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarCloseOpen}
                            onClose={this.handleCloseSnackbarClose}
                            message='You have successfully cancelled this order'
                        />
                        {/* NOTES */}
                        <Dialog 
                            open={this.state.dialogNotesOpen} 
                            onClose={this.handleNotesClose} 
                            fullWidth={true} 
                            maxWidth="sm" 
                            scroll="body"
                            disableBackdropClick
                            disableEscapeKeyDown
                        >
                            <DialogTitle id="form-dialog-title">Add Notes</DialogTitle>
                            <DialogContent>
                                <FormControl error={formErrors && formErrors['noteType'] && true} fullWidth margin="normal">
                                    <AutoCompleteSelect
                                        options={[
                                            {value: 'Order Creation', label: 'Order Creation'},
                                            {value: 'Approval', label: 'Approval'},
                                            {value: 'Confirmed', label: 'Confirmed'},
                                            {value: 'Payment', label: 'Payment'},
                                            {value: 'Payment Confirmed', label: 'Payment Confirmed'},
                                            {value: 'Picking', label: 'Picking'},
                                            {value: 'Despatch', label: 'Despatch'},
                                            {value: 'Invoice', label: 'Invoice'},
                                            {value: 'Completed', label: 'Completed'},
                                            {value: 'Cancelled', label: 'Cancelled'},
                                            {value: 'Declined', label: 'Declined'}
                                        ]}
                                        label='Note Type *'
                                        value={this.state.formData.noteType}
                                        onChange={this.handleSelectChange('noteType')}
                                        error={formErrors && formErrors['noteType'] && true}
                                        errorText={formErrors && formErrors['noteType'] && formErrors['noteType']}
                                    />
                                </FormControl>
                                <Textarea
                                    id="notesText"
                                    name="notesText"
                                    label="Notes *"
                                    value={this.state.formData.notesText}
                                    rows={5}
                                    error={formErrors && formErrors['notesText']}
                                    onChange={this.handleChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleNotesClose} variant="outlined" color="default">Cancel</Button>
                                <Button onClick={this.handleNotesSuccess} autoFocus variant="contained" color="primary">Save</Button>
                            </DialogActions>
                        </Dialog>
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarNotesOpen}
                            onClose={this.handleNotesSnackbarClose}
                            message='You have successfully added new notes'
                        />
                        {/* COURIER DETAILS */}
                        <Dialog 
                            open={this.state.dialogCourierOpen} 
                            onClose={this.handleCourierClose} 
                            fullWidth={true} 
                            maxWidth="sm" 
                            scroll="body"
                            disableBackdropClick
                            disableEscapeKeyDown
                        >
                            <DialogTitle id="form-dialog-title">{dialogCourierData?.full_reference ?? 'No Despatch Note'}</DialogTitle>
                            <DialogContent>
                                <Typography variant='body1' gutterBottom>Courier</Typography>
                                <Typography variant='subtitle2' gutterBottom>
                                    {dialogCourierData?.courier?.cour_name} <br />
                                    {(dialogCourierData?.tracking_links?.length > 0 ? 
                                            dialogCourierData?.tracking_links.map((tl, idx) => (
                                                <><br /><a href={tl?.desp_tracking_url} className='blueLink' target='_blank'><i>{tl?.desp_tracking_url}</i></a></>
                                            ))
                                            
                                        :
                                            <small><i>No tracking details</i></small>
                                        )
                                    }
                                </Typography>
                                <br />
                                <Typography variant='body1' gutterBottom>Courier Despatch Note(s)</Typography>
                                {dialogCourierData?.despatchNotes?.length > 0 ? 
                                    dialogCourierData?.despatchNotes.map((rev, idx) => (
                                        <Grid container key={idx} >
                                            <Grid item xs={1}>
                                                { idx === (dialogCourierData?.despatchNotes?.length - 1) ? 
                                                    <Tooltip title={'Delete'}>
                                                        <IconButton size='small' onClick={()=>this.handleDeleteFileConfirmation(rev.co_file_id)}>
                                                            <FALightIcon icon='trash-alt' size="small" noMargin button /> 
                                                        </IconButton> 
                                                    </Tooltip>    
                                                    : <></> 
                                                }
                                            </Grid>
                                            <Grid item xs={11} style={{padding:'2px'}}>
                                                <MuiLink component="div" variant="body2" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={() => {downloadS3File(rev.file_url)}}>
                                                    {rev.co_file_filename || '-'}
                                                </MuiLink>
                                            </Grid>
                                        </Grid>
                                    ))
                                : (
                                    (
                                        dialogCourierData?.files?.length > 0 ?
                                            <>
                                                <Grid container  >
                                                    {dialogCourierData?.files.map( (f, idx) => 
                                                        <>
                                                            <Grid item xs={1}>    
                                                                { idx === (dialogCourierData?.files?.length - 1) ? 
                                                                    <Tooltip title={'Delete'}>
                                                                        <IconButton size='small' onClick={()=>this.props.deployConfirmation(
                                                                            'Are you sure you want to delete this file?',
                                                                            'Delete File',
                                                                            this.deleteNoDespatchFile(f.ondnf_id)
                                                                        )}>
                                                                            <FALightIcon icon='trash-alt' size="small" noMargin button /> 
                                                                        </IconButton> 
                                                                    </Tooltip>    
                                                                    : <></> 
                                                                }
                                                            </Grid>
                                                            <Grid item xs={11} style={{padding:'2px'}}>
                                                                <MuiLink component="div" variant="body2" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={() => {downloadS3File(f.file_url)}}>
                                                                    {_.last(f.ondnf_file.split('/')) || '-'}
                                                                </MuiLink>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </> :
                                            <Typography variant="subtitle2">
                                                <small>No Files</small>
                                            </Typography>
                                    )
                                )}
                                <br />
                                <Typography variant='body1'>Courier Notes</Typography>
                                <TextField
                                    value={dialogCourierData?.desp_courier_notes || dialogCourierData?.ondn_courrier_notes}
                                    rows={5}
                                    variant="outlined"
                                    multiline
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCourierClose} variant="outlined" color="default">Close</Button>
                            </DialogActions>
                        </Dialog>
                        {/* Upload New Payment */}
                            <Dialog
                                open={this.state.dialogUploadOpen}
                                onClose={this.handleUploadClose}
                                fullWidth={true}
                                maxWidth="sm" 
                                scroll="body"
                                disableBackdropClick
                                disableEscapeKeyDown
                                >
                                <DialogTitle id="form-dialog-title">Upload Payment File</DialogTitle>
                                {this.state.dialogUploadOpen &&
                                    <DialogContent>
                                        <Grid item xs={12}>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true} className={classes.label} style={{paddingBottom: '1.8em'}}>Current File</InputLabel>
                                                <TextField
                                                    style={{marginTop: '0.7em'}}
                                                    className={classes.label}
                                                    fullWidth
                                                    value={this.state.dialogUploadData?.file ? _.last(this.state.dialogUploadData?.file.split('/')) : 'No File'}
                                                    disabled
                                                    InputProps={{
                                                        endAdornment: this.state.dialogUploadData?.file && (<AllIcon icon={icons.search} onClick={this.viewFile(this.state.dialogUploadData?.file)} noMargin />)
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true} >Upload New File</InputLabel>
                                                <DragFileInput
                                                    id="file"
                                                    name="file"
                                                    file={this.state.dialogUploadFormData.file}
                                                    errorText={formErrors && formErrors['file']}
                                                    onChange={this.handleDialogUploadFileChange}
                                                    emptyText='No file selected'
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='caption'></Typography>
                                        </Grid>
                                    </DialogContent>
                                }   
                                <DialogActions>
                                    <Button variant='outlined' onClick={this.handleUploadClose} color="default">Close</Button>
                                    <Button variant='contained' color='secondary' onClick={
                                        ()=>{
                                            this.props.deployConfirmation(
                                                'Are you sure you want to delete this file',
                                                'Delete Payment File',
                                                this.handleDialogUploadFileDelete
                                            )
                                        }
                                    } disabled={!this.state.dialogUploadData?.file} >Delete File</Button>
                                    <Button variant='contained' color='primary' onClick={
                                        ()=>{
                                            this.props.deployConfirmation(
                                                'Are you sure you want to upload a new payment file?. please note: this will overwrite the current file',
                                                'Upload New Payment File',
                                                this.handleDialogUploadFileSubmit
                                            )
                                        }
                                    } disabled={!this.state.dialogUploadFormData.file} >Upload</Button>
                                </DialogActions>
                            </Dialog>
                        {/* PAYMENT DETAILS */}
                        <Dialog 
                            open={this.state.dialogPaymentOpen} 
                            onClose={this.handlePaymentClose} 
                            fullWidth={true} 
                            maxWidth="sm" 
                            scroll="body"
                            disableBackdropClick
                            disableEscapeKeyDown
                        >
                            <DialogTitle id="form-dialog-title">Payment Details</DialogTitle>
                            <DialogContent>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel shrink={true} className={classes.label}>Payment Date</InputLabel>
                                    <Input value={moment(dialogPaymentData.paymentDate).format('DD/MM/YYYY')} disabled />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel shrink={true} className={classes.label}>Payment Method</InputLabel>
                                    <Input value={(dialogPaymentData.paymentMethod == 'Card' || dialogPaymentData.paymentMethod == 'Stripe' ) ? ((dialogPaymentData.paymentMethod == 'Stripe') ? 'Card - Stripe' : 'Card - Barclaycard') : dialogPaymentData.paymentMethod} disabled />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel shrink={true} className={classes.label}>Payment Amount</InputLabel>
                                    <Input value={`£${dialogPaymentData.paymentAmount}`} disabled />
                                </FormControl>
                                {(dialogPaymentData.paymentMethod == 'Bank Transfer' || dialogPaymentData.paymentMethod == 'Bank Transfer (PR)') &&
                                    <>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel shrink={true} className={classes.label}>Ref</InputLabel>
                                            <Input value={dialogPaymentData?.bankTransferRef} disabled />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel shrink={true} className={classes.label}>Payee</InputLabel>
                                            <Input value={dialogPaymentData?.bankTransferPayee} disabled />
                                        </FormControl>
                                    </>
                                }
                                {(dialogPaymentData.paymentMethod == 'Cheque' || dialogPaymentData.paymentMethod == 'Cheque (PR)') &&
                                    <>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel shrink={true} className={classes.label}>Cheque Number</InputLabel>
                                            <Input value={dialogPaymentData?.chequeNumber} disabled />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel shrink={true} className={classes.label}>Cheque Payee</InputLabel>
                                            <Input value={dialogPaymentData?.chequePayee} disabled />
                                        </FormControl>
                                    </>
                                }
                                {(dialogPaymentData.paymentMethod == 'Card' || dialogPaymentData.paymentMethod == 'Card (PR)') &&
                                    <>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel shrink={true} className={classes.label}>Payment Ref</InputLabel>
                                            <Input value={dialogPaymentData?.cardPaymentRef} disabled />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel shrink={true} className={classes.label}>Auth No</InputLabel>
                                            <Input value={dialogPaymentData?.cardAuthNumber} disabled />
                                        </FormControl>
                                    </>
                                }
                                {(dialogPaymentData.paymentMethod == 'PayPal' || dialogPaymentData.paymentMethod == 'PayPal (PR)') &&
                                    <>
                                    {
                                        ( dialogPaymentData?.paypalShippingAddress ) ?
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true} className={classes.label}>PayPal Shipping Address</InputLabel>
                                                <Input multiline value={dialogPaymentData?.paypalShippingAddress} disabled />
                                            </FormControl> :
                                            <>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel shrink={true} className={classes.label}>Payment Ref</InputLabel>
                                                    <Input value={dialogPaymentData?.cardPaymentRef} disabled />
                                                </FormControl>
                                            </>
                                    }
                                    
                                    </>
                                }
                                {(dialogPaymentData.paymentMethod == 'Stripe' || dialogPaymentData.paymentMethod == 'Stripe (PR)') &&
                                    <>
                                    { ( dialogPaymentData?.stripeCardType ) ? 
                                        <>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true} className={classes.label}>Card Type</InputLabel>
                                                <Input value={dialogPaymentData?.stripeCardType} disabled />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true} className={classes.label}>Card Number</InputLabel>
                                                <Input value={dialogPaymentData?.stripeCardNumber} disabled />
                                            </FormControl>
                                        </> : 
                                        <>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true} className={classes.label}>Payment Ref</InputLabel>
                                                <Input value={dialogPaymentData?.cardPaymentRef} disabled />
                                            </FormControl>
                                        </>
                                
                                    }
                                    
                                    </>
                                }
                                {dialogPaymentData.paymentMethod == 'Direct Debit' &&
                                    <>
                                    <FormControl margin="normal" fullWidth>
                                        <InputLabel shrink={true} className={classes.label}>Ref</InputLabel>
                                        <Input value={dialogPaymentData?.directDebitRef} disabled />
                                    </FormControl>
                                    </>
                                }
                                {dialogPaymentData.paymentMethod == 'FOC' &&
                                    <>
                                    <FormControl margin="normal" fullWidth>
                                        <InputLabel shrink={true} className={classes.label}>Reason</InputLabel>
                                        <Input value={dialogPaymentData?.focReason} disabled />
                                    </FormControl>
                                    </>
                                }
                                <Grid item xs={12}>
                                    <FormControl margin="normal" fullWidth>
                                        <InputLabel shrink={true} className={classes.label} style={{paddingBottom: '1.8em'}}>Payment File</InputLabel>
                                        <TextField
                                            style={{marginTop: '0.7em'}}
                                            className={classes.label}
                                            fullWidth
                                            value={dialogPaymentData.file ? _.last(dialogPaymentData.file.split('/')) : 'No File'}
                                            disabled
                                            InputProps={{
                                                endAdornment: dialogPaymentData.file && (<AllIcon icon={icons.search} onClick={this.viewFile(dialogPaymentData.file)} noMargin />)
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handlePaymentClose} variant="outlined" color="default">Close</Button>
                            </DialogActions>
                        </Dialog>
                        <ConfirmationDialog 
                            close   = {this.handleCloseDeleteNote} 
                            open    = {this.state.confirmNoteDelete}
                            title   = "Delete Note?"
                            message = "Are you sure you want to delete this note?"
                            success = {this.deleteNote}
                        />
                        <ConfirmationDialog 
                            close   = {this.handleDeleteFileConfirmation} 
                            open    = {this.state.confirmFileDelete}
                            title   = "Delete file?"
                            message = "Are you sure you want to delete this file?"
                            success = {this.deleteFile}
                        />
                            <Dialog 
                                open={this.state.showPushBackOrder}  
                                maxWidth="md" 
                                scroll="body"
                            >
                                <DialogTitle>
                                    <Typography>Are you sure you want to push back this order</Typography>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container xs={12}>
                                        <Grid item xs={12} >
                                            <FormControl fullWidth margin="none" style={{margin:0, marginTop: -10}}>
                                                <Textarea
                                                    style={{margin:0}}
                                                    id="reason"
                                                    name="reason"
                                                    label="Push Back Reason *"
                                                    value={this.state.pushBackFormData.reason}
                                                    onChange={(e) => { this.setState({ pushBackFormData: {...this.state.pushBackFormData, reason: e.target.value}}) }}
                                                    error={formErrors && formErrors['reason']}
                                                    rows={4}
                                                />
                                            </FormControl>
                                            {!!this.state.pushBackFormData.removePayment &&
                                                <Typography style={{color: colors.red, fontWeight: 'bold'}}>Please Note: This order has had a payment of £{parseFloat(_.sumBy( _.filter(this.state.co.payments, {op_txn_status: 'Authorised'}), i => parseFloat(i.op_txn_amount))).toFixed(2)}, pushing this back will remove this payment.</Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button 
                                        onClick={()=>{this.setState({showPushBackOrder: false})}}
                                        variant="outlined" 
                                        color="default" 
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        onClick={this.handlePushBack}
                                        variant="contained" 
                                        color="secondary" 
                                        disabled={!this.state.pushBackFormData.reason.replaceAll(' ','')}
                                    >
                                        Push Back
                                    </Button>
                                </DialogActions>
                            </Dialog>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                    => dispatch(setPersistence(key, state)),
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                              => dispatch(closeDialog()),
    deployConfirmation: (content, title, success)       => dispatch(deployConfirmation(content, title, success)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewOrder));